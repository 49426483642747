import React from 'react'
import "../CSS/ClassForChildren.css";
import FooterComponent from '../Component/FooterComponent';
import ShowImage from "../Images/section-separator-bottom.png";
import TheaterActingImage from "../Images/HomeClass01.jpg"
const ClassForChildren = () => {
    return (
        <>
            {/* ///////////////////////////////////////// Section 1 //////////////////////////////////////////////////// */}

            <section className='CFC-Section01'>
                <div className='container'>
                    <div className='CFC-Title'>
                            <h1>Acting Class & Theatre For Childrens - Special Class </h1>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='CFCTimeSchedule'>
                                <div className='CFCTimeSchedule-title'>
                                    <h3>Timing & Schedule</h3>
                                </div>
                                <div className='CFCTimeSchedule-info'>
                                    <h5>New Batch    :    Start from 3rd Novemder 2022</h5>
                                    <h5>Course            :    Camera Acting , Theatre & Dance</h5>
                                    <h5>Duration       :    1 Month, 4 Month </h5>
                                    <h5>Schedule       :    Monday To Sunday  </h5>
                                    <h5>Holiday          :    Wednesday  </h5>
                                    <h5>Age Criteria :    5 year to 12 year  </h5>

                                </div>
                            </div>

                        </div>
                        <div className='col-md-6'>
                            <div className='CFCFeeDetails'>
                                <div className='CFCFeeDetails-title'>
                                    <h3>Fee Details</h3>
                                </div>
                                <div className='CFCFeeDetails-info'>
                                    <h5>Admission Fee  :-   1500 Compulsory</h5>
                                    <h5>Full Time Fee    :-    1 Month:-  Rs.  30000/-<br></br>
                                        4 Month:-  Rs.  90,000/- </h5>
                                    <h5>Payment Mode :-    Cash, Cheque, UPI Transfer & Online </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* ///////////////////////////////////////////////// Show Image /////////////////////////////////////////////// */}
            <section className='showSection'>
                <div className='SHowImage'>
                    <img src={ShowImage} alt="" />
                </div>
            </section>


            {/* ///////////////////////////////////////// Section 2 ///////////////////////////////////////////////////////////////// */}

            <section className='CFC-Section2'>
                <div className='container'>
                    <div className='CFC_Schedule-Title'>
                        <h5>Schedule</h5>
                        <h4>Schedule</h4>
                    </div>
                    <div className='ToursInfo-Section3-Itinerary'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='CFC-Container'>
                                    <div className='CFC-Images'>
                                        <img src={TheaterActingImage} alt="" />
                                    </div>

                                </div>
                            </div>

                            <div className='col-md-8                                                                                                                                                                                                                         '>
                                <div className='CFC-Second-Container'>
                                    <div className='CFC-Schedule-heading'>
                                        <h4>Everyday Schedule</h4>
                                    </div>
                                    <div className='CFC-Schedule-Info'>
                                        <ul>
                                            <li>Meditation Concentration & Imagination Physical Exercise  (Eyes-Face-Body) Theatre Play WorkshopTheatre Play Rehearsal Dance Class</li>
                                            <li>Improvisation Speech & Observation</li>
                                            <li>Body Language according to Character,Face Movement According Camera,Body Movement According Camera</li>
                                            <li>Voice Modulation  Variations Dialogue DeliveryPronunciation  Diction (Hindi, Reginal Language & Urdu Pronunciation)</li>
                                            <li>9 Ruses, Face Expressions, Emotions, Reactions, Opposite Reactions & Performances of various moods</li>
                                            <li>Characterization, Technical Details for Camera, Camera Facing, Eyes & Face Control according to Camera, Auditions Practice, Monologues, Show reel. </li>
                                            <li>Energy Label Improvement, Breathing, Sound Practice from Head to Stomach</li>
                                            <li>*Live Theatre Performance on stage,Audition Practice Every Week,Portfolio Shoot,Auditions Approach,Street play work,Short Film, Ad Film, Feature Film &
                                                Web Series Shooting Work,Accommodation Facilities </li>

                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterComponent />
        </>
    )
}

export default ClassForChildren;