import React from 'react';
import "../CSS/AboutUs.css";
import Footer from "../Component/FooterComponent";
// about Image  
import aboutImage1 from "../Images/Home/About/AboutUs1.png";
import aboutImage2 from "../Images/Home/About/AboutUs2.jpeg";
import ShowImage from "../Images/section-separator-bottom.png";
// Team Image 
import TeamImage01 from "../Images/Our-Team01.jpg";
import TeamImage02 from "../Images/Our-Team02.jpg";


const AboutUs = () => {
    return (
        <>
            {/* /////////////////////////// Section 1 /////////////////////////////////////////// */}
            <section className='AboutUs-Section'>
                <div className='AboutUs-banner-Image'>
                    <div className='AboutUs-Banner-text'>
                    </div>
                </div>
            </section>



            {/* ///////////////////////////////////////////////// added section////////////////////////////////////////////////////// */}

            <section className='Aboutnew'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='welcome-Image-con'>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-6'>
                                        <div className='AboutUs-AB-FImage'>
                                            <img src={aboutImage1} alt="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6 col-sm-6'>
                                        <div className='AboutUs-AB-SImage'>
                                            <img src={aboutImage2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <div className='AboutNewSection'>
                                <div className='AboutUsNewSctionInfo'>
                                    <div className='AboutUs-New-Heading'>
                                        <h6>THE PROFESSIONAL</h6>
                                        <h3>ABSS</h3>
                                        <p> We had started ABSS with NGO- Akhil Bhartiya Sanskritik Sansthan with eleven artists at Gandhi Bhawan Lucknow showing a rocking “stage play” on Gandhi Jayanti Mahotsav on 2nd October 1990. Today we are with more than 2500 artists and have been performed 700 plays and about 2500 road Dramas (Nukkad Nataks) at various locations across the India.</p>
                                        <p>Our provenance is demonstrated and supported by the fact that we design and perform theatre shows and street shows for uplifting and espousing a particular cause which holds some relevance to impact the society. Below is a list of the prevalent causes concerning and demanding our uttermost attention for which we have performed and still do innumerable acts with a selfless intention of edifying the crowd onto matters with strong message and abrupt action.</p>
                                        <p>Abss Acting Institute and Theatre Group is a prominent establishment dedicated to nurturing and honing the talents of aspiring actors. Known for its comprehensive training programs, the institute offers a variety of courses tailored to different levels of experience, from beginners to seasoned performers. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>Under the guidance of experienced professionals, students receive rigorous training in various aspects of acting, including voice modulation, body language, improvisation, and character development. The institute emphasizes practical learning through regular workshops, stage performances, and interactions with industry experts.</p>
                                        <p>The Theatre Group associated with Abss is renowned for its high-quality productions, showcasing a diverse range of plays that span different genres and themes. This provides students with ample opportunities to gain real-world experience and build their portfolios.</p>
                                        <p>In addition to technical skills, Abss Acting Institute and Theatre Group focuses on the holistic development of its students, encouraging creativity, confidence, and collaboration. With a commitment to excellence and a supportive learning environment, the institute has established itself as a leader in the field of acting education and theatre production.</p>
                    </div>
                </div>
            </section>

            {/* ///////////////////////////////////////////////// Show Image /////////////////////////////////////////////// */}
            <section className='showSection'>
                <div className='SHowImage'>
                    <img src={ShowImage} alt="" />
                </div>
            </section>

            {/* ///////////////////// About Section 3 //////////////////////// */}

            <section className='About-Section-3'>
                <div className='container'>
                    <div className='About-Section-3-Points-con'>
                        <div className='About-Section-3-head-points'>
                            <div className='About-Section-3-head-con'>
                                <h3 className='textstyle'>All  over   India   Branches </h3>
                                <p> Mumbai (Maharshtra), Lucknow (U.P), Hardoi (U.P) & Khanpur (U.P.) </p>
                            </div>
                            <div className='About-Section-3-head-con'>
                                <h3 className='textstyle'>Theatre play &  live  shows</h3>
                                <p> We have been performed more than 500 Theatre & Live shows across the India. </p>
                            </div>
                            <div className='About-Section-3-head-con'>
                                <h3 className='textstyle'>Health  related show </h3>
                                <p> Till Date we have performed approx. 3000 Road Dramas on HIV AIDS, Pulse Polio, Tikakaran, Cancer, Addiction, Awareness of Drugs etc. </p>
                            </div>
                            <div className='About-Section-3-head-con'>
                                <h3 className='textstyle'>Educational,  social  and  cultural  activities </h3>
                                <p> Till date we have performed more than 900 shows and dramas on Educations, Family Management, Population, Superstitions, Water Conservation, Pollution & Environment, Terrorism, Religion, Swachhata Abhiyaan & Cultural Programmes. </p>
                            </div>
                            <div className='About-Section-3-head-con'>
                                <h3 className='textstyle'>Shows  for   disaster  &  risk   management  </h3>
                                <p> So for we have performed more than 100 shows on Flood, Fire, Earthquake, Tsunami, Traffic, etc. </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}



            {/* <section className='AboutUs-Section2'>
                <div className='row'>
                    <div className='col-lg-4 col-sm-12'>
                        <Slide left>
                            <div className='AboutUs-Image'>
                                <div className='AboutUs_Image-Container'>
                                    <img src={AboutUS} alt="" />
                                </div>
                            </div>
                        </Slide>
                    </div>
                    <div className='col-lg-8 col-sm-12'>
                        <Slide right>
                            <div className='AboutUs-Info'>
                                <div className='AboutUs_Details'>
                                    <h3>
                                        About Us
                                    </h3>
                                    <p>We had started Akhil Bhartiya Sanskritik Sansthan with eleven artists at Gandhi Bhawan Lucknow showing a rocking 'stage play' on Gandhi Bhawan Jayanti 2 Oct 1990. Today, We have done more than 1500 plays with over 5000 artists at various locations across India. Currently, We are situated at Andheri W. We focus on Inner acting and outer acting to enhance the skills of our artists. Hence, we bring the best in them. Listening and understanding our customers and providing tailored solutions is something that makes us stand apart from our competitors.</p>
                                </div>
                            </div>
                        </Slide>
                    </div>
                </div>
            </section> */}


            {/* /////////////////////////////////// Section 3 /////////////////////////////////////////// */}

            {/* <section className='AboutUs-Section3'>
                <div className='container'>

                    <div className='About-Founder'>

                        <div className='About-Founder-Title'>
                            <h3>Founder</h3>
                            <div className='Founder-Info'>
                                <p>
                                    Rajendra Tiwari is the founder of A.B.S.S. Theatre Group, and has been prevalent in this industry for more than three decades continually striving on impacting the industry with his stupendous works and thereby building his immense portfolio.
                                </p>
                            </div>
                            <Bounce>
                                <div className='Founder-Images'>
                                    <div className='FImage'>
                                        <img src={FImage} alt="" />
                                        <div className='FName'>
                                            <h6>Rajendra Tiwari</h6>
                                        </div>
                                    </div>
                                </div>
                            </Bounce>
                            <div className='Founder-Description'>
                                <Zoom>
                                    <p>Born on December 1, 1967, and to a middle-class family in Lucknow which believed in simplicity and generosity, Rajendra Tiwari was innately endued upon with positive and open-handed behavior. This is what helped him in his early days of establishing his theatre group’s foundations in the city of dreams when he first came in 2009. Mumbai at its first glance cold-shouldered him in his initial phase. He was barely coping up with the fast-paced impending obstacles concurrent to his dream of setting up the biggest theatre group in the city. His family would implore him to return to their native place, but he remained unwavering in his decision to pursue his own forged aspirations. When at a time ‘A.B.S.S.’ was no more than admiration to an apprentice attempt in building a castle where young actors would get trained, today this name is on the tip of everyone’s tongue. Rajendra Tiwari strolled, scattered, exterminate, and infrequently crawled but he did finish the race in making this name the most sought-after theatre group brand in the existing time.</p>
                                </Zoom>
                                <Zoom>
                                    <p>He has been for more than 20 years preaching uttermost devotion to acting, and expounds and proclaimed the same to countless students. He, apart from being an actor, has donned the cap of a director, a writer, and a producer. Below is an instance of his persistent, unflinching, and truthful complication and devotion towards this industry.</p>
                                </Zoom>
                            </div>

                        </div>
                    </div>

                </div>
            </section> */}



            {/* /////////////////////////////////////// Our-team /////////////////////////////////////// */}
            {/* <section className='Our-Team-Section'>
                <div className='container'>
                    <div className='Our-Team-heading-Container'>
                        <div className='OurTeam-Heading'>
                            <h5>Our Team</h5>
                            <p>Our great composers & dance group will have works performed by professional ensembles</p>
                        </div>
                        <div className='OurTeam-Back'>
                            <h1>Team</h1>
                        </div>
                    </div>

                    <div className='row'>

                        <div className='col-md-4'>
                            <div className='Our-Team-details'>
                                <div className='Our-Team-image'>
                                    <img src={RajendraTwari} alt="" />
                                    <div className='our-Team-Name'>
                                        <h4>Rajendra Twari</h4>
                                    </div>
                                </div>
                                <div className='Our-Team-Info'>
                                    <h4>Rajendra Twari</h4>
                                    <h6>Director</h6>
                                </div>
                            </div>
                        </div>


                        <div className='col-md-4'>
                            <div className='Our-Team-details'>
                                <div className='Our-Team-image'>
                                    <img src={DeepakThapa} alt="" />
                                    <div className='our-Team-Name'>
                                        <h4>Deepak Thapa</h4>
                                    </div>
                                </div>
                                <div className='Our-Team-Info'>
                                    <h4>Deepak Thapa</h4>
                                    <h6>Choreographer</h6>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <div className='Our-Team-details'>
                                <div className='Our-Team-image'>
                                    <img src={NishthaTiwari} alt="" />
                                    <div className='our-Team-Name'>
                                        <h4>Nishtha Tiwari</h4>
                                    </div>
                                </div>
                                <div className='Our-Team-Info'>
                                    <h4>Nishtha Tiwari</h4>
                                    <h6>Creative Head & Camera Class Teacher</h6>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section> */}



            <Footer />


        </>
    )
}

export default AboutUs