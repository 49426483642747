import demo from "../Images/Alumni/Adarsh Cheema.jpeg"


const Facultydata = [
    {
        id:1,
        FImage:demo,
        Fdescription:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur soluta impedit nemo suscipit porro, a expedita corrupti fugit id sequi!"
    },
    {
        id:1,
        FImage:demo,
        Fdescription:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur soluta impedit nemo suscipit porro, a expedita corrupti fugit id sequi!"
    },
    {
        id:1,
        FImage:demo,
        Fdescription:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur soluta impedit nemo suscipit porro, a expedita corrupti fugit id sequi!"
    },
    {
        id:1,
        FImage:demo,
        Fdescription:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur soluta impedit nemo suscipit porro, a expedita corrupti fugit id sequi!"
    },
    {
        id:1,
        FImage:demo,
        Fdescription:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur soluta impedit nemo suscipit porro, a expedita corrupti fugit id sequi!"
    },
    {
        id:1,
        FImage:demo,
        Fdescription:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur soluta impedit nemo suscipit porro, a expedita corrupti fugit id sequi!"
    },
    {
        id:1,
        FImage:demo,
        Fdescription:"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur soluta impedit nemo suscipit porro, a expedita corrupti fugit id sequi!"
    },
];
export default Facultydata;