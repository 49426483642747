import React from 'react';
import "../Faculty/AshwiniShukla.css";
import AshwiniShuklaImage from "../../Images/ABSS Teachers/Ashwani Shukla.png";
import Footer from "../FooterComponent";
const AshwiniShukla = () => {
    return (
        <>
            <section className='AshwiniShukla-Section'>
                <div className='AshwiniShukla-banner-Image'>
                    <div className='AshwiniShukla-Banner-text'>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-2'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section2-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='rajendra-tiwari-Section2-Image-con'>
                                    <div className='rajendra-tiwari-Section2-Image'>
                                        <img src={AshwiniShuklaImage} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='rajendra-tiwari-Section2-details-con'>
                                    <div className='rajendra-tiwari-Section2-details-head'>
                                        <h3 className='rajendra-tiwari-text-style'>Ashwini Shukla</h3>
                                    </div>
                                    <p>Ashwani is a passionate and dedicated professional with over a decade of experience in the theatre and film industry. Since 2010, he has been working as a management expert at ABSS Acting and Theatre Group, where he plays a pivotal role in shaping the careers of aspiring actors.</p>
                                </div>
                                <div className='rajendra-tiwari-Section2-details-con'>
                                    <div className='rajendra-tiwari-Section2-details-head'>
                                        <h3 className='rajendra-tiwari-text-style'>Professional Journey</h3>
                                    </div>
                                    <p>Ashwani began his career with a focus on theatre and quickly rose to prominence as a key figure at ABSS Acting and Theatre Group. His expertise in management has been instrumental in the group’s success, providing guidance and support to newcomers eager to break into the film industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Vision and Mission </h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>Ashwani believes in the transformative power of the arts and is committed to nurturing new talent in the film industry. His mission is to create a supportive environment where aspiring actors can develop their skills and achieve their dreams. Through his work, Ashwani aims to contribute to the growth and enrichment of the film and theatre community.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Achievements</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3><b>Management Expertise: </b>Over a decade of successful management at ABSS Acting and Theatre Group, mentoring countless newcomers in their acting careers..</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3><b>.Film Production:</b> Actively involved in film production, contributing to the creation and success of various film projects.</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3><b>Guidance and Mentorship:</b>  Renowned for providing invaluable guidance and mentorship to aspiring actors, helping them navigate the complexities of the film industry.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Work as an Production Head, Production Controller, Line Producer, Executive Producer & Casting Director </h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>1. Hindi Feature Film-  KHADANJA</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Work in Production Controller with Raj Kaushik Ji</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>1. UDATI HUI TASHTARI PRODUCTOON,</h3>
                                <p>Subject - DOTS</p>
                                <p>Production - UDATI HUI TASHTARI</p>
                                <p>Director - Pushpendra Mishra</p>
                                <p>Actor - Vivek Oberoi</p>
                                <p>Production controller.  – Raj Kaushik</p>
                                <p>Date- 25 nov. 2008</p>
                                <p>Lucknow. U.P.</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>2. RED & ICE PRODUCTION</h3>
                                <p>Subject - Visa Debit Card</p>
                                <p>Production - Red & Ice </p>
                                <p>Director - Anurag Bose</p>
                                <p>Actor - Boman Irani & Divya Dutta</p>
                                <p>Production controller – Raj Kaushik</p>
                                <p>Date- 6, April, 2010</p>
                                <p>Royal Palm, Goregaon (e), Mumbai</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>3. PHOTO SHOOT</h3>
                                <p>Bajaj Auto Production controller   – Raj Kaushik </p>
                                <p>Date- 13, April, 2010</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>4. RED CHILLI PRODUCTION </h3>
                                <p>Subject - EVEREST PANI PURI MASALA</p>
                                <p>Production - Red Chilli Production</p>
                                <p>Production controller – Raj Kaushik</p>
                                <p>Date- 23, April, 2010</p>
                                <p>Jurashik Park Malvani-4, Malad (w)</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>5. IKON PRODUCTION PRODUCTION</h3>
                                <p>Subject - FILM- WILL YOU MARRY ME </p>
                                <p>Production - IKON</p>
                                <p>Director - Aditya Dutt</p>
                                <p>Actor - Shreyash Tadpade, Muzamil Khan & Rajeev Khandelwal, Mugdha Godsay, Selina Jetaly & Tripta Jain</p>
                                <p>Producer - Kisan ji & Sunil Sahgal</p>
                                <p>Production controller – Raj Kaushik</p>
                                <p>Date- 13 – 22 July,2010</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>6. RED & ICE PRODUCTION</h3>
                                <p>Subject - Vasmol 33 Keshkala Dye</p>
                                <p>Production -  RED & ICE</p>
                                <p>Production controller – Raj Kaushik</p>
                                <p>Date-  23, July,2010</p>
                                <p>LOCATION -  MUD Malad</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>7. R.K. Swamy Media Company</h3>
                                <p>Subject - LIC</p>
                                <p>Production - R.K. Swamy</p>
                                <p>Production controller – Raj Kaushik</p>
                                <p>Date - 22, August,2010</p>
                                <p>LOCATION - V.T</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>8. BLACK COFFY  PRODUCTION</h3>
                                <p>Subject- NIKITA CHIPS & PAPAD</p>
                                <p>Production controller – Raj Kaushik</p>
                                <p>Date- 31, OCTOBER, 2010</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>9. RED & ICE PRODUCTION</h3>
                                <p>Subject - Cello pen</p>
                                <p>Production controller – Raj Kaushik</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>10.	RED & ICE PRODUCTION</h3>
                                <p>Subject - LG AC</p>
                                <p>Production controller – Raj Kaushik</p>
                                <p>Date- Andheri East </p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>11.	RED & ICE PRODUCTION</h3>
                                <p>Subject - Ujala Neel</p>
                                <p>Location - Mahboob Studio, Bandra west </p>
                                <p>Production controller – Raj Kaushik</p>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3 className='ashwinshukla-title'>12.	RED & ICE PRODUCTION</h3>
                                <p>Subject -  Kurkure</p>
                                <p>Location - Pali Hill, Bandra West</p>
                                <p>Actor - Juhi Chawala</p>
                                <p>Production controller – Raj Kaushik</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AshwiniShukla