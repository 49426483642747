import React from 'react';
import FooterComponent from '../FooterComponent';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Button, Form, Input } from 'antd';
import styleImage from "../../Images/play.png";
import { TiStarburst } from "react-icons/ti";
import "../Courses/ClassesForChildren.css";


import classesforchildren1 from "../../Images/home gallery/Slide Pics43.jpg";
import classesforchildren2 from "../../Images/home gallery/Slide Pics33.jpg";
import classesforchildren3 from "../../Images/home gallery/Slide Pics46.jpg";
import classesforchildren4 from "../../Images/home gallery/Slide Pics45.jpg";
import $ from 'jquery';
import TextArea from 'antd/es/input/TextArea';

const ClassesForChildren = () => {
    const [form] = Form.useForm();

    const submitFrom = (values) => {
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#821f40">ABSS</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#821f40"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Courses:</strong></td><td style="text-align:left">' + values.courses + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Contact No:</strong></td><td style="text-align:left">' + values.contact + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Message:</strong></td><td style="text-align:left">' + values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at ABSS</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: "info@abssgroup.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "ABSS",
            accountLeadSource: "https://abssgroup.com/",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);
            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        form.resetFields();
        values.preventDefault()
        return false;
    }

    return (
        <>
            <section className='acting-courses-section-1-main-con classesforchildren-section-1-back-image'>
                <div className='acting-courses-section-banner-con'>
                    {/* <div className='action-courses-section-text'>
                        <h1>Classes For Children</h1>
                        <h2>Classes For Children</h2>
                    </div> */}
                </div>
            </section>

            <section className='acting-courses-section-2'>
                <div className='container'>
                    <div className='acting-courses-section-2-main-con'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='acting-courses-section-2-details-main-con'>
                                    <div className='acting-courses-section-2-details'>
                                        <Swiper
                                            spaceBetween={30}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={classesforchildren1} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={classesforchildren2} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={classesforchildren3} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={classesforchildren4} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>

                                    <div className='acting-courses-section-2-details-text-con'>
                                        <div className='theater-group-section-2-details-text-con'>

                                            <div className='theater-group-section-2-details-text'>
                                                <div className='theater-group-section-2-details-head-con'>
                                                    <div className='theater-group-section-2-details-icons'>
                                                        <TiStarburst />
                                                    </div>
                                                    <h3>Acting increasingly recognized as a viable career option</h3>
                                                </div>
                                            </div>

                                            <div className='theater-group-section-2-details-text'>
                                                <div className='theater-group-section-2-details-head-con'>
                                                    <div className='theater-group-section-2-details-icons'>
                                                        <TiStarburst />
                                                    </div>
                                                    <h3>Growing enthusiasm among children for acting</h3>
                                                </div>
                                            </div>

                                            <div className='theater-group-section-2-details-text'>
                                                <div className='theater-group-section-2-details-head-con'>
                                                    <div className='theater-group-section-2-details-icons'>
                                                        <TiStarburst />
                                                    </div>
                                                    <h3>Joy in facilitating acting classes for children</h3>
                                                </div>
                                            </div>

                                            <div className='theater-group-section-2-details-text'>
                                                <div className='theater-group-section-2-details-head-con'>
                                                    <div className='theater-group-section-2-details-icons'>
                                                        <TiStarburst />
                                                    </div>
                                                    <h3>Firm belief in nurturing talents and empowering children</h3>
                                                </div>
                                            </div>

                                            <div className='theater-group-section-2-details-text'>
                                                <div className='theater-group-section-2-details-head-con'>
                                                    <div className='theater-group-section-2-details-icons'>
                                                        <TiStarburst />
                                                    </div>
                                                    <h3>Guidance provided by Rajendra Tiwari</h3>
                                                </div>
                                            </div>

                                            <div className='theater-group-section-2-details-text'>
                                                <div className='theater-group-section-2-details-head-con'>
                                                    <div className='theater-group-section-2-details-icons'>
                                                        <TiStarburst />
                                                    </div>
                                                    <h3>Friendly and supportive learning environment</h3>
                                                </div>
                                            </div>

                                            <div className='theater-group-section-2-details-text'>
                                                <div className='theater-group-section-2-details-head-con'>
                                                    <div className='theater-group-section-2-details-icons'>
                                                        <TiStarburst />
                                                    </div>
                                                    <h3>Topics covered:</h3>
                                                </div>
                                                <ul>
                                                    <li>Meditation</li>
                                                    <li>Imagination</li>
                                                    <li>Physical exercises</li>
                                                    <li>Character immersion</li>
                                                    <li>Improvisation</li>
                                                    <li>Storytelling</li>
                                                    <li>Speech</li>
                                                    <li>Observation</li>
                                                    <li>Body language alignment</li>
                                                    <li>Voice modulation</li>
                                                    <li>Pronunciation</li>
                                                    <li>Diction in multiple languages</li>
                                                </ul>
                                            </div>

                                            <div className='theater-group-section-2-details-text'>
                                                <div className='theater-group-section-2-details-head-con'>
                                                    <div className='theater-group-section-2-details-icons'>
                                                        <TiStarburst />
                                                    </div>
                                                    <h3>Learning about:</h3>
                                                </div>
                                                <ul>
                                                    <li>Facial expressions</li>
                                                    <li>Emotions</li>
                                                    <li>Reactions</li>
                                                    <li>Character portrayal in different moods</li>
                                                    <li>Energy level enhancement</li>
                                                    <li>Breathing techniques</li>
                                                    <li>Sound practices</li>
                                                </ul>
                                            </div>

                                            <div className='theater-group-section-2-details-text'>
                                                <div className='theater-group-section-2-details-head-con'>
                                                    <div className='theater-group-section-2-details-icons'>
                                                        <TiStarburst />
                                                    </div>
                                                    <h3>Engagements include:</h3>
                                                </div>
                                                <ul>
                                                    <li>Theatre workshops</li>
                                                    <li>Play rehearsals</li>
                                                    <li>Live stage performances</li>
                                                    <li>Street plays</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='stock-details-section-2-right-side-main-con'>
                                    <div className='stock-details-2-right-side-form'>
                                        <div className='stock-details-2-right-side-form-title'>
                                            <h3>Classes For Children</h3>
                                        </div>
                                        <div className='stock-details-2-right-side-form-price'>
                                            <div className='stock-details-2-form-price-and-quent'>
                                                <Form
                                                    layout='vertical'
                                                    initialValues={{
                                                        courses: 'Classes For Children'
                                                    }}
                                                    onFinish={submitFrom}
                                                >
                                                    <Form.Item name="name" label="Name">
                                                        <Input
                                                            placeholder='Name'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="contact" label="Contact">
                                                        <Input
                                                            placeholder='Contact No.'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="courses" label="Courses Name">
                                                        <Input
                                                            placeholder='Courses'
                                                            disabled
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="message" label="Message">
                                                        <TextArea
                                                            rows={4}
                                                            placeholder='Message'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button htmlType='submit'>
                                                            Enquire Now
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterComponent />
        </>
    )
}

export default ClassesForChildren