import React from 'react';
import "../CSS/TheaterActing.css";
import Footer from "../Component/FooterComponent";
import TheaterImage01 from "../Images/TheaterImage01.jpg";
import TheaterImage02 from "../Images/TheaterImage02.jpg";
import ReactPlayer from 'react-player';
import ShowImage from "../Images/section-separator-bottom.png"



const TheaterActing = () => {
  return (
    <>
      {/* ////////////////////////////////////// Section 1 //////////////////////////////////// */}


      <section className='TA-Section01'>
        <div className='container'>
          <div className='TA-Section01-title'>
              <h1>THEATRE CLASSES</h1>
            <p>Best Theatre Groups in Mumbai</p>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <div className='TATimeSchedule'>
                <div className='TATimeSchedule-image'>
                  <img src={TheaterImage01} alt="" />
                </div>
                <div className='TATimeSchedule-title'>
                  <h3>Timing & Schedule</h3>
                </div>
                <div className='TATimeSchedule-info'>
                  <h5>Batch             :  From 11th October - New Batches start</h5>
                  <h5>Duration       :  Monthly, 3 Month, 6 Month & 1 Year</h5>
                  <h5>Schedule       :  Monday To Sunday </h5>
                  <h5>Holiday         :  Wednesday  </h5>
                  <h5>Age Criteria : 15 years and above </h5>
                  <div className='DP-Time'>
                    <h5>Timing</h5>
                    <h5> : 1st Batch     - 8 Am to 10 Am<br></br>
                      2nd Batch  - 10 Am to 12 Pm<br></br>
                      3rd Batch   - 4 Pm to 6 Pm<br></br>
                      4th Batch   -  6 Pm to 8 Pm<br></br>
                      5th Batch   -  8 Pm to 10 Pm</h5>
                  </div>
                </div>
              </div>

            </div>
            <div className='col-md-6'>
              <div className='TAFeeDetails'>
                <div className='TAFeeDetails-Image'>
                  <img src={TheaterImage02} alt="" />
                </div>
                <div className='TAFeeDetails-title'>
                  <h3>Fee Details</h3>
                </div>
                <div className='TAFeeDetails-info'>
                  <h5>Admission Fee      :  Rs. 1500/-</h5>
                  <h5>Full Time Course :  Rs. 90000/- For 4 Month
                    Rs. 250000/- For 1 year </h5>
                  <h5>Payment Mode :-    Cash, Cheque, UPI Transfer & Online </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////////////////////////////// Show Image /////////////////////////////////////////////// */}
      <section className='showSection'>
        <div className='SHowImage'>
          <img src={ShowImage} alt="" />
        </div>
      </section>

      {/* ////////////////////////////////////// Section 2 //////////////////////////////////// */}

      <section className='TA-Section02'>
        <div className='TA-Section02-title'>
          <h3>*Theatre Acting Course Modules*</h3>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <div className="TA-Section02-info">

              <div className="TA-Section02-info-title">
                <h4> We do rehearsal according to Camera Pattern.</h4>
              </div>
              <div className='TA-Section02-info-description'>
                <p>Theatre play practice daily according to script.</p>
                <p>Daily story reading, characterizations & analysis on character's psychology.</p>
                <p>Practice of character with blocking.</p>
                <p>Practice of character with body language, reactions, emotions & costume-property.</p>
                <p>Live Theatre shows in Auditorium within 3 months.</p>
                <p>Energy Label Improvement, Breathing, Sound Practice from Head to Stomach.</p>
                <p><strong>Extra Benefits:</strong>*Short Film Shooting, Web Series Shooting, Portfolio Shoot, Auditions  </p>
                <p> Approach,  Accommodation Facilities</p>
              </div>
            </div>

          </div>
          <div className='col-md-6'>
            <div className='TA-Section02-Image'>
              <div className='TheaterActingImage'>
                <ReactPlayer autoplay controls width='100%' height='100%' url="https://www.youtube.com/watch?v=2DMm7VaCraA" />
              </div>
            </div>
          </div>

        </div>
      </section>

      <Footer />

    </>
  )
}

export default TheaterActing;