import React from 'react';
import "../AboutPages/Palcement.css";
import Footer from "../../Component/FooterComponent";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper';

import placement31 from "../../Images/Placement/31.avif";
import placement1 from "../../Images/Placement/1.avif";
import placement2 from "../../Images/Placement/2.avif";
import placement3 from "../../Images/Placement/3.avif";
import placement4 from "../../Images/Placement/4.avif";
import placement5 from "../../Images/Placement/5.avif";
import placement6 from "../../Images/Placement/6.avif";
import placement7 from "../../Images/Placement/7.avif";
import placement8 from "../../Images/Placement/8.avif";
import placement9 from "../../Images/Placement/9.avif";
import placement10 from "../../Images/Placement/10.avif";
import placement11 from "../../Images/Placement/11.avif";
import placement12 from "../../Images/Placement/12.avif";
import placement13 from "../../Images/Placement/13.avif";
import placement14 from "../../Images/Placement/14.avif";
import placement15 from "../../Images/Placement/15.avif";
import placement16 from "../../Images/Placement/16.avif";
import placement17 from "../../Images/Placement/17.avif";
import placement18 from "../../Images/Placement/18.avif";
import placement19 from "../../Images/Placement/19.avif";
import placement20 from "../../Images/Placement/20.avif";
import placement21 from "../../Images/Placement/Devesh Sharma.avif";
import placement22 from "../../Images/Placement/Hunza Sabir.avif";
import placement23 from "../../Images/Placement/Kabir Singh Bisnoi A.avif";
import placement24 from "../../Images/Placement/maxresdefault.avif";
import placement25 from "../../Images/Placement/Mni-tv.avif";
import placement26 from "../../Images/Placement/Nida Khan.avif";
import placement27 from "../../Images/Placement/Pradeep Nigam 1.avif";
import placement28 from "../../Images/Placement/Ramayan.avif";
import placement29 from "../../Images/Placement/Rudraksh Jaiswal A.avif";
import placement30 from "../../Images/Placement/Samaira Jaiswal A.avif";


const Placement = () => {
    return (
        <>
            <section className='Placement-section-1'>
                <div className='container'>
                    <div className='Placement-Section-Details-con'>
                        <div className='Placement-Section-Details-Info-con'>
                            <h2 className='headstyle'>Casting Wing Of ABSS Acting Institute</h2>
                        </div>

                        <div className='Placement-Section-1-Details-Info-con'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='Placement-Section-1-Info-Para'>
                                        <p>At ABSS, we are dedicated to nurturing our students' acting talents so thoroughly that wherever they go, they do not need to search for work. The truth is, work eludes us when we lack talent, but with talent, even if work comes a bit late, it eventually arrives. Our goal is to ensure that our students never lack talent. Our institute makes every effort to ensure that after their training, students find the best work in the right direction and at the right place without needing to search aimlessly.</p>
                                        <p>To achieve this, we send actors to production houses and casting directors, allowing them to secure work that matches their talent. Our institute is well-known to experienced production houses and casting directors, where mentioning ABSS often helps in securing work easily. Additionally, ABSS has a system through WhatsApp and phone calls where we send the profiles of selected students, making it easier for them to find work. We also provide students with the contact numbers and addresses of production houses and casting directors.</p>
                                        <p>Our institute has a dedicated casting team that assists students in finding work. The best part about ABSS is that you remain connected with us for life, and we are committed to helping you find work throughout your career.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='placement-Section-2-placement-clients'>
                            <div className='placement-Section-2'>
                                <div className='Placement-Section-Details-Info-con'>
                                    <h2 className='headstyle'>We continuously receive casting requests from the following production houses</h2>
                                </div>

                                <div className='placement-Section-clients-points-con'>
                                    <div className='row row-cols-lg-3 row-cols-sm-2 row-cols-2'>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Yash Raj Films
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Balaji Telifilms
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Balaji Motion Pictures
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Mukesh Chhabra Casting Company
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Casting Q Company
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Every Friday Telefilms film Production house
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Dharma Productions
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Mehata Telefilms
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Meraki Films LLP
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Reliance Entertainment
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Anurag Kashyap Films
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Bhansali Productions
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Cinemagic Entertainment
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Flyking Entertainment
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Lemon N Yellow Productions
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    White Feather Films
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Shalmalaa Films
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Director’s Kut Productions
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Hatts Off Productions
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Neela Tele Films
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Sphere Origins
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Contiloe Pictures
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Rajshree Productions
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Creative Eye Productions
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    B.A.G. Films
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Swastik Productions
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Optimistix
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Saibaba Studio
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Sagar Arts Productions
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Rashmi Sharma Telefilms
                                                </a>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='Placement-Clients-Points'>
                                                <a href='' className='placement-points-icons'>
                                                    Vishesh Films
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='placement-section-3-con'>
                                    <div className='main-section-3-para-con'>
                                        <p>Also have an arrangement with various coordinating agencies for placement for our passed out students. Few Production Houses also conduct their auditions at the institute premises to choose from our actors. In view of providing platform to our passed out students our website also has a profile of all  our actors which makes it accessible to various production houses, casting agencies and co-ordinations who are on lookout for actors for their projects. After short listing the actors from the profile, one can get in touch with the institute and continue with their process of casting our actors.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='placement-section-images-swiper-con'>
                <div className='container'>
                    <div className='placement-section-images-swiper'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                576: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement1} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement2} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement3} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement18} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement20} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement4} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement5} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement6} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement7} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement8} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement9} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement10} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement11} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement12} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement13} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement14} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement15} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement16} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement17} />
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement19} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement21} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement31} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement22} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement23} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement24} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement25} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement26} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement27} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement28} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement29} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='placement-section-images-swiper-last-section'>
                                    <img src={placement30} />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Placement