import React from 'react';
import FooterComponent from '../FooterComponent';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Button, Form, Input } from 'antd';
import styleImage from "../../Images/play.png";
import "../../Component/Courses/ActingWorkshop.css";

import ActingWorkshop1 from "../../Images/Courses/acting workshop 1.jpg";
import ActingWorkshop2 from "../../Images/Courses/acting workshop 5.jpg";
import ActingWorkshop3 from "../../Images/Courses/acting workshop.jpg";
import $ from 'jquery';
import TextArea from 'antd/es/input/TextArea';

const ActingWorkshop = () => {


    const [form] = Form.useForm();

    const submitFrom = (values) => {
        var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#821f40">ABSS</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#821f40"><strong>Name:</strong></td><td style="text-align:left">' + values.name + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Courses:</strong></td><td style="text-align:left">' + values.courses + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Contact No:</strong></td><td style="text-align:left">' + values.contact + '</td></tr><tr><td style="text-align:left;color:#821f40"><strong>Message:</strong></td><td style="text-align:left">' + values.message + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at ABSS</p></div></body></html>';

        $.post("https://skdm.in/server/v1/send_lead_mail.php", {
            toEmail: "info@abssgroup.com",
            fromEmail: "skdmlead@gmail.com",
            bccMail: "skdmlead@gmail.com",
            mailSubject: "New Customer Lead",
            mailBody: body,
            accountName: "ABSS",
            accountLeadSource: "https://abssgroup.com/",
            accountLeadName: values.name,
            accountLeadEmail: values.email,
            accountLeadPhone: values.contact,
        },

            function (dataa, status) {
                console.log('data :' + dataa);
                console.log("name:" + values);
            });

        alert("Your Form has Submitted Our team will contact with You  soon.");
        form.resetFields();
        values.preventDefault()
        return false;
    }


    return (
        <>
            <section className='acting-courses-section-1-main-con ActingWorkshop-section-1-back-image'>
                <div className='acting-courses-section-banner-con'>
                    {/* <div className='action-courses-section-text'>
                        <h1>Acting Workshop</h1>
                        <h2>Acting Workshop</h2>
                    </div> */}
                </div>
            </section>

            <section className='acting-courses-section-2'>
                <div className='container'>
                    <div className='acting-courses-section-2-main-con'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='acting-courses-section-2-details-main-con'>
                                    <div className='acting-courses-section-2-details'>
                                        <Swiper
                                            spaceBetween={30}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            navigation={true}
                                            modules={[Autoplay, Pagination, Navigation]}
                                            className="mySwiper"
                                        >
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={ActingWorkshop1} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={ActingWorkshop2} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className='acting-courses-section-2-swiper'>
                                                    <div className='acting-courses-section-2-details-images'>
                                                        <img src={ActingWorkshop3} alt='actingcourses' />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>

                                    <div className='acting-courses-section-2-details-text-con'>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Weekly workshops cover all facets of acting, providing essential understanding before entering the industry.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Participants receive guidance from actors, directors, producers, writers, and technical teams from film and television.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Renowned Bollywood figures conduct workshops, sharing insights and expertise.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Casting directors offer valuable advice for navigating the industry.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Focus on enhancing energy levels, breathing techniques, and vocal practices.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Technical aspects like camera handling, facial control, audition preparation, and showreel production are thoroughly addressed.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Practical experience includes monologue shoots, portfolio shoots, and audition simulations.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>The platform allows aspiring actors to learn, refine skills, and gain insights from industry professionals.</p>
                                        </div>

                                        <div className='acting-courses-section-2-details-text'>
                                            <div className='acting-courses-section-2-details-text-image'>
                                                <img src={styleImage} alt='' />
                                            </div>
                                            <p>Comprehensive training prepares participants for success in the competitive entertainment field.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='stock-details-section-2-right-side-main-con'>
                                    <div className='stock-details-2-right-side-form'>
                                        <div className='stock-details-2-right-side-form-title'>
                                            <h3>Acting Workshop</h3>
                                        </div>
                                        <div className='stock-details-2-right-side-form-price'>
                                            <div className='stock-details-2-form-price-and-quent'>
                                                <Form
                                                    layout='vertical'
                                                    initialValues={{
                                                        courses: 'Acting Workshop'
                                                    }}
                                                    onFinish={submitFrom}
                                                >
                                                    <Form.Item name="name" label="Name">
                                                        <Input
                                                            placeholder='Name'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="contact" label="Contact">
                                                        <Input
                                                            placeholder='Contact No.'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="courses" label="Courses Name">
                                                        <Input
                                                            placeholder='Courses'
                                                            disabled
                                                        />
                                                    </Form.Item>
                                                    <Form.Item name="message" label="Message">
                                                        <TextArea
                                                            rows={4}
                                                            placeholder='Message'
                                                        />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <Button htmlType='submit'>
                                                            Enquire Now
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent />
        </>
    )
}

export default ActingWorkshop