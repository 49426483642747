import React, { useRef, useState } from 'react';
import "../Faculty/RajendraTiwari.css";
import Footer from "../../Component/FooterComponent";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper';

import RajendraTiwariImage from "../../Images/ABSS Teachers/Rajendra Twari.png";

import RajendraTiwariFaculty01 from "../../Images/Faculty new/Rajendra Tiwari Faculty01.avif";
import RajendraTiwariFaculty02 from "../../Images/Faculty new/Rajendra Tiwari Faculty02.avif";
import RajendraTiwariFaculty03 from "../../Images/Faculty new/Rajendra Tiwari Faculty03.avif";
import RajendraTiwariFaculty04 from "../../Images/Faculty new/Rajendra Tiwari Faculty04.avif";
import RajendraTiwariFaculty05 from "../../Images/Faculty new/Rajendra Tiwari Faculty05.avif";
import RajendraTiwariFaculty06 from "../../Images/Faculty new/Rajendra Tiwari Faculty06.avif";
import RajendraTiwariFaculty07 from "../../Images/Faculty new/Rajendra Tiwari Faculty07.avif";
import RajendraTiwariFaculty08 from "../../Images/Faculty new/Rajendra Tiwari Faculty08.avif";
import RajendraTiwariFaculty09 from "../../Images/Faculty new/Rajendra Tiwari Faculty09.avif";
import RajendraTiwariFaculty10 from "../../Images/Faculty new/Rajendra Tiwari Faculty10.avif";
import RajendraTiwariFaculty11 from "../../Images/Faculty new/Rajendra Tiwari Faculty11.avif";
import RajendraTiwariFaculty12 from "../../Images/Faculty new/Rajendra Tiwari Faculty12.avif";
import RajendraTiwariFaculty13 from "../../Images/Faculty new/Rajendra Tiwari Faculty13.avif";
import RajendraTiwariFaculty14 from "../../Images/Faculty new/Rajendra Tiwari Faculty14.avif";
import RajendraTiwariFaculty15 from "../../Images/Faculty new/Rajendra Tiwari Faculty15.avif";
import RajendraTiwariFaculty16 from "../../Images/Faculty new/Rajendra Tiwari Faculty16.avif";
import RajendraTiwariFaculty17 from "../../Images/Faculty new/Rajendra Tiwari Faculty17.avif";
import RajendraTiwariFaculty18 from "../../Images/Faculty new/Rajendra Tiwari Faculty18.avif";
import RajendraTiwariFaculty19 from "../../Images/Faculty new/Rajendra Tiwari Faculty19.avif";
import RajendraTiwariFaculty20 from "../../Images/Faculty new/Rajendra Tiwari Faculty20.avif";
import RajendraTiwariFaculty21 from "../../Images/Faculty new/Rajendra Tiwari Faculty21.avif";
import RajendraTiwariFaculty22 from "../../Images/Faculty new/Rajendra Tiwari Faculty22.avif";
import RajendraTiwariFaculty23 from "../../Images/Faculty new/Rajendra Tiwari Faculty23.avif";


const RajendraTiwari = () => {
    return (
        <>
            <section className='RajendraTiwari-Section'>
                <div className='RajendraTiwari-banner-Image'>
                    <div className='RajendraTiwari-Banner-text'>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-2'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section2-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='rajendra-tiwari-Section2-Image-con'>
                                    <div className='rajendra-tiwari-Section2-Image'>
                                        <img src={RajendraTiwariImage} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='rajendra-tiwari-Section2-details-con'>
                                    <div className='rajendra-tiwari-Section2-details-head'>
                                        <h3 className='rajendra-tiwari-text-style'>Rajendra Tiwari (Founder, Writer & Director)</h3>
                                    </div>
                                    <p>Welcome to ABSS acting institute and theater group , where every story told has a unique journey behind it. I am Rajendra Tiwari, the director, writer and founder of ABSS, and I want to share with you the transformative path that has led me to the director's chair of this exceptional institution with 3 decades of experience as an actor, director, writer and ideal of countless students.</p>
                                    <p>My journey in the world of acting began with humble beginnings, facing challenges that many aspiring artists encounter. From auditions that seemed elusive to roles that felt out of reach, my early years were marked by perseverance and an unyielding passion for the craft. It was during these moments of struggle that I learned the true value of resilience and determination.I established my theatre group’s foundation in the city of dreams Mumbai in 2009 when I came to Mumbai.</p>
                                </div>
                            </div>
                        </div>
                        <div className='rajendra-tiwari-Section2-details-para'>
                            <p>As I navigated the intricacies of the performing arts landscape, I encountered diverse experiences that shaped my understanding of the industry. From film, webseries, TV serials, Radio serial, Telefilms, documentary film, hindi drama, awadhi drama to  Nukkad natak for information department of uttar Pradesh government each step was a lesson, each setback a chance to grow. ABSS is not just a culmination of my personal journey but a tribute to the countless individuals who face challenges on their path to artistic expression. Here, we celebrate the triumphs born from struggles, the performances crafted through perseverance, and the stories that emerge from the tapestry of personal growth.I am honored to be a part of this community that understands the transformative power of struggle and the beauty that emerges from it. Join us at ABSS as we embrace the diverse narratives that shape our journeys and celebrate the resilience that defines us as artists.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-4'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-4-con'>
                        <div className='rajendra-tiwari-Section-4-head-con'>
                            <h2>Esteemed Cultural Practitioner with Extensive Experience in Theater, Film, and Academia</h2>
                        </div>
                        <div className='rajendra-tiwari-Section-4-details'>
                            <div className='rajendra-tiwari-Section-4-details-head'>
                                <h2>1. Attached to Sangeet Natak Akademi (SNA) and Lucknow University in the Cultural Department.</h2>
                            </div>
                            <div className='rajendra-tiwari-Section-4-details-head'>
                                <h2>2. Ten years’ experience with K.P. Saxena, the writer of the film "Lagaan."</h2>
                            </div>
                            <div className='rajendra-tiwari-Section-4-details-head'>
                                <h2>3. Experience at the Bikaner Theater Festival, sharing the stage during a theater seminar with:</h2>
                                <h3>- Devendra Raj Ankur (Former NSD Director)</h3>
                                <h3>- Sanjana Kapoor (Owner of Prithvi Theatre, Mumbai)</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-4-details-head'>
                                <h2>4. Work experience with directors of NSD & BNA and renowned theater theorists:</h2>
                                <h3>- Alok Chatarjee (Gold Medalist of NSD & Former Director of MPSD-MP)</h3>
                                <h3>- Surya Mohan Kulshreshtha (Former BNA Director)</h3>
                                <h3>- Jugal Kishore (Former officiating Director of BNA & Famous Theorist)</h3>
                                <h3>- Arun Trivedi (Former BNA Director)</h3>
                                <h3>- Urmil Kumar Thapaliyal (Honored Senior Playwright & Famous Theorist)</h3>
                                <h3>- Anil Rashtogi (Famous Theorist & Film Actor)</h3>
                                <h3>- Santram Shukla (Senior Theorist & King of Voice Acting)</h3>
                                <h3>- Amrendra Sahay (Senior Theorist, Writer & Director)</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-4-details-head'>
                                <h2>5. Affiliated with IPTA (Indian People's Theatre Association) for over 10 years, since 1985. Worked with:</h2>
                                <h3>- A.K. Hangal (Film Actor & Famous Theorist)</h3>
                                <h3>- Shabana Azmi (Actress)</h3>
                                <h3>- Kaifi Azmi (Urdu Poet & Lyricist)</h3>
                                <h3>- Ravi Nagar (Lyricist, Musician & Singer)</h3>
                                <h3>- Kumud Nagar (Senior Theorist)</h3>
                                <h3>- Mithilesh Chaturvedi (Actor)</h3>
                                <h3>- Akhilendra Mishra (Actor)</h3>
                                <h3>- Anjan Shrivastav (Actor)</h3>
                                <h3>- Dadhi Pandey (Actor)</h3>
                                <h3>- Mudra Rakshasa (Writer)</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-4-details-head'>
                                <h2>6. Work experience with Bollywood producers, directors, and actors:</h2>
                                <h3>- Arbaaz Khan (Producer)</h3>
                                <h3>- Anup Jalota (Bhajan Samrat, Producer & Actor)</h3>
                                <h3>- Vivek Prakash (Music Director & Singer)</h3>
                                <h3>- Prabhakar Shukla (Director & Producer)</h3>
                                <h3>- Vivek Oberoi (Actor)</h3>
                                <h3>- Rajpal Yadav (Actor)</h3>
                                <h3>- Shekhar Suman (Actor)</h3>
                                <h3>- Gauri Khan (Actress)</h3>
                                <h3>- Yunus Parvez (Actor)</h3>
                                <h3>- Razzak Khan (Actor)</h3>
                                <h3>- Mushtaq Khan (Actor)</h3>
                                <h3>- Vikas Anand (Actor)</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-4-details-head'>
                                <h2>6. In 2024, made his first Hindi feature film debut as a Dialect Coach in the movie "Patna Shukla," produced by Arbaaz Khan Productions and directed by Vivek Budakoti. Actors in "Patna Shukla" include:</h2>
                                <h3>- Raveena Tandon</h3>
                                <h3>- Satish Kaushik</h3>
                                <h3>- Manav Vij</h3>
                                <h3>- Chandan Roy</h3>
                                <h3>- Jatin Goswami</h3>
                                <h3>- Anushka Kaushik</h3>
                                <h3>- Alok Chatterjee</h3>
                            </div>
                        </div>

                        <div className='rajendra-tiwari-Section-4-last-para'>
                            <p>Conducted workshops for all the actors in this film.</p>
                            <p>In 2024, wrote and directed his second Hindi feature film, "Khadanja," starring Junior Mahmood and Pradeep Kabra in lead roles. All actors are from the ABSS Group.</p>
                            <p>In 2024, wrote his second Hindi feature film, "Dr. Hedgewar."</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Hindi Film</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='row row-cols-lg-3 row-cols-sm-2 row-cols-2'>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>1. Dialect Coach- Hindi Film <span> “Patna Shukla” </span> of Arbaz Khan Production House in 2024</h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>2. Writer & director- Hindi Film <span> “Khadanja” </span> of Shalmalaa Films Production House  in 2024</h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>3. Writer - Hindi Film <spam> “Dr. Hedgewar” </spam> of Akshay Shetty Memorial Foundation Production House in 2024</h3>
                                    </div>
                                </div>
                                {/* <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>4. Machhrehata <span>(Writer)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>5. Go To Hell <span>(Writer)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>6. Pihu <span>(Writer)</span></h3>
                                    </div>
                                </div> */}
                                {/* <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>4. Kadariya <span>(Writer)</span></h3>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Web-Series</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='row row-cols-lg-3 row-cols-sm-2 row-cols-2'>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>1. Ye Hai… Chunaav <span>(Writer)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>2. Dastan-E-Shaheed <span>(Writer)</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>TV Serials</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='row row-cols-lg-2 row-cols-sm-2 row-cols-2'>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>1. Pyar  Karo  Magar Pyar  Se   (DD Lucknow -1998)  <span> Associate Director & Writer </span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>2. Hum Hain Rahi Pyar Ke (DD Lucknow-1999) <span>Associate Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>3. Geet Bahar (DD Lucknow -1999) <span> Associate Director & Writer </span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>4. Sargam Ki Duniya (DD Lucknow -2000) <span>Associate Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>5. Hanste  Gaate (DD Lucknow -2009) <span>Associate Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>6. Toni the Great (Sewa Films, Lucknow, U.P. 1998) <span>Associate Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>7. Imtihaan (DD National) (Status Air Vision Pvt Ltd. Mumbai) <span>Writer</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Radio Serial</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>1. Naya Yug Nayee Kranti - <span>Dharavahik (Vividh Bharti, Lucknow -1996) Director & Writer</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Telefilms</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='row row-cols-lg-3 row-cols-sm-2 row-cols-2'>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>1. Ek Hi Rasta <span> Director & Writer </span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>2. Sanmarg <span>Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>3. Pati Chahiye <span>Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>4. Kal Dekha Jayega <span>Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>5. Jagrati <span>Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>6. Daraar <span>Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>7. Anjaam <span>Director & Writer</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Documentary Film</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='row row-cols-lg-3 row-cols-sm-2 row-cols-2'>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>1. Gramya Vikash <span>Director & Writer</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>2. Gramya Suraksha <span>Director & Writer</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Director of Hindi Dramas (1990-2023, More Than 700 Shows):-</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='row row-cols-lg-3 row-cols-sm-2 row-cols-2'>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>1. Aisa Bhi Hota Hai <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>2. Main Vo Nahin <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>3. Baadh Ka Paani <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>4. Aman Yaar Bolne Toh Do <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>5. Suraj Aur Prakash <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>6. Sangharsh <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>7. Waah Re Zamane <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>8. Lucknow-Aazadi Ki Raah Par <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>9. Tension Shuru <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>10.	Murtikar <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>11.	Jaag Utho Navchetana Mein <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>12. Rehearsal <span>( Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>13. Kajri <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>14. Aah <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>15.	Laut  Ke  Buddhu  Ghar  Ko  Aaya <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>16.	Rehearsal <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>17.	Saazish <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>18.	Dr. Yamraj Ka Davakhana <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>19. Lahoo Ke Rang <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>20.	Aazadi Ki Raah <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>21. Bechara Mohandas <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>22. Pratishodh <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>23.	Aparadhi <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>24. Lute Udhar Mein Pite Pyar Mein <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>25.	Udisa  Mein  Yamraj <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>26.	Rahasya <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>27.	Aakanksha <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>28.	Alert India <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>29.	Chaahat <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>30.	Waah Re Ahinsa <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>31.	Kaise Nahin Marega Gandhi <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>32.	Hum Bhi Hain Line Mein <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>33.	Kya Main Zinda Hoon <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>34.	Mujhe Gulami Do <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>35.	Chunaav <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>36.	Chunaav <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>37. Black out <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>38.	Kyun <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>39.	Uff Ladies <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>40.	Hamse Kaa Bhool Hui <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>41.	Marriage Buero <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>42.	Bebas Tiranga <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>43.	Banda Kamaal Tha <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>44.	Bermuda Triangle Mein Bharat <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>45.	Pati Chahiye-Part-1 <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>46.	Pati Chahiye-Part-2 <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>47.	Sergical Strike <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>48.	Khaamosh Cheekh <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>49.	Jalte Talwe <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>50.	Tumse Na Ho Payega <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>51.	Dastan-E-Hindustan <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>52.	Khandhar <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>53.	Dhatt Tere Ki <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>54.	Lockdown <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>55.	Black Tiger <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>56.	Samay Se Pahle Maut Kyun <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>57.	Kadariya <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>58.	LockDown <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>59.	Dhatt Tere Ki- Part-2 (Haspatal) <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>60.	Aahat <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>61.	Karele Ki Arthi <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>62.	Dhatt Tere Ki- Part- 3 (Court) <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 
            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Awadhi Dramas</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='row row-cols-lg-3 row-cols-sm-2 row-cols-2'>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>1. Kajri </h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>2. Aah </h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>3. Laut Ke Buddhu Ghar Ko Aaya </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>Nukkad Natak for Information department - Utter Pradesh Government (1991-2023, More Than 4000 Shows)</h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='row row-cols-lg-3 row-cols-sm-2 row-cols-2'>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>1. Family Planning U.P. Government <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>2. AIDS Controls Society U.P. <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>3. Pulse Polio <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>4. Tikakaran <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>5. Balika Shiksha Abhiyaan <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>6. Pradooshan <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>7. Cancer <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>8. Paryavaran <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>9. Sinchai Vibhag <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>10. Apada Prabandhan <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>11. Swachhata Abhiyaan <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>12.	Awareness of Drugs <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>13.	Education <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='rajendra-tiwari-Section-3-points'>
                                        <h3>14.	Yoga <span>(Writer & Director)</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-4-images-con'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-4-images-heading'>
                        <h2 className='headstyle'>Iconic Moments with Celebrities</h2>
                    </div>
                    <div className='rajendra-tiwari-Section-4-images-div'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty01} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty02} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty03} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty04} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty05} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty06} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty07} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty08} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty09} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty10} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty11} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty12} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty13} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty14} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty15} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty16} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty17} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty18} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty19} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty20} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty21} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty22} />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='rajendra-tiwari-Section-4-images'>
                                    <img src={RajendraTiwariFaculty23} />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default RajendraTiwari