import React from 'react';
import "../CSS/FAQ.css";
import FooterComponent from '../Component/FooterComponent';

const Blog = () => {
  return (
    <>
      {/* /////////////////////////// Section 1 /////////////////////////////////////////// */}
      <section className='FAQ-Section'>
        <div className='FAQ-banner-Image'>
          <div className='FAQ-Banner-text'>
            <h1>FAQ</h1>
            <h4>Frequently Asked Questions</h4>
          </div>
        </div>
      </section>

      {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

      <section className='FAQ-Section02'>
        <div className='container'>
          <div className='FAQName'>
            <h5>FAQ</h5>
            <h4>FAQ</h4>
          </div>

          <div className='FAQ-Info'>
            <div class="accordion" id="accordionExample">


              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFirst">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFirst" aria-expanded="true" aria-controls="collapseFirst">
                    What language is the medium of instruction?
                  </button>
                </h2>
                <div id="collapseFirst" class="accordion-collapse collapse" aria-labelledby="headingFirst " data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>The course is primarily conducted in the Hindi language. However all our faculty understand and speak English fluently and on request will offer a translation. We have had many students who were either only fluent with English or with Hindi and have still successfully completed the course.</p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSecond">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSecond" aria-expanded="false" aria-controls="collapseSecond">
                    Does language matter – English or Hindi?
                  </button>
                </h2>
                <div id="collapseSecond" class="accordion-collapse collapse" aria-labelledby="headingSecond" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>The science behind training an individual to act professionally is universal. However, the medium one wants to act in is language-dependent. If you want to be an actor in Hindi cinema, you need to know the Hindi language. If you wish to work as an actor in international cinema, you need to know the language of that specific industry. At Actor, whatever industry you wish to act in does not matter as we teach you the techniques of acting that are universally applicable. The classes are primarily conducted in Hindi. However, all our faculty understand and speak English fluently and, upon request, will offer a translation.</p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThird">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThird" aria-expanded="false" aria-controls="collapseThird">
                    Who are the teachers who teach at Actor Prepares?
                  </button>
                </h2>
                <div id="collapseThird" class="accordion-collapse collapse" aria-labelledby="headingThird" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>We have some of the best acting trainers in the country teaching full time, exclusively at Actor Prepares. All our acting faculty are extensively trained and have a minimum of 10 to 15 years of experience as actors and trainers.</p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    What facilities do you provide our students?
                  </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>We do provide a lot of facilities to the students. Few of those facilities are the theatre, library and the pantry.</p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    Why don’t we provide demo or trial classes?
                  </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>Actor is the best private acting institute in the country. We have built our worldwide reputation over the years by providing quality training and world-class facilities. Being a trusted brand, we do not offer any demo sessions. Unlike dance or other performing arts, one cannot experience or claim to provide the experience of acting in a single demo class; hence the exclusion.</p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSix">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    Do we provide hostel facility?
                  </button>
                </h2>
                <div id="collapseSix" class="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      Actor does not have any in-house hostel, PG, or rental apartment facility. However, being located in the heart of the city, individuals can find PG, hostel, flat sharing, or private apartment accommodations easily as per their preference, ranging from Rs. 10,000/- and upwards.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSeven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    What would be the charges for PG/flat?
                  </button>
                </h2>
                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>Actor does not have any in-house hostel, PG, or rental apartment facilities. However, being located in the heart of the city, individuals can find PG, hostel, flat-sharing, or private apartment accommodations easily as per their preference, ranging from Rs. 10,000/- and upwards.</p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingEight">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                    Once paid if I wish to withdraw from the course, will my fees be refunded?
                  </button>
                </h2>
                <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>If admission is granted to an applicant and on receipt of fee from that applicant a seat is blocked for the specific programme, no refund will be offered.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <FooterComponent />


    </>
  )
}

export default Blog;