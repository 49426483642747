import React, { useState } from 'react';
import "../CSS/ImageGallery.css";
import Footer from "../Component/FooterComponent";

import ImageGalleryData from '../Component/ImageGalleryData';
import Model from '../Component/Model';

const ImageGallery = () => {

    const [clickImage, setClickImage] = useState(null)
    const [cureentIndex, setCureentIndex] = useState(null)

    const handelClick = (item, index) => {
        setCureentIndex(index);
        setClickImage(item.gImage);
        // console.log(clickImage)
        // console.log(cureentIndex)
    };

    // const some = ImageGalleryData.length
    // console.log(some)

    const handelRotationRight = () => {
        const totallength = ImageGalleryData.length;

        if (cureentIndex + 1 >= totallength) {
            setCureentIndex(0);
            const newurl = ImageGalleryData[0].gImage;
            setClickImage(newurl)
            return;
        };
        const newIndex = cureentIndex + 1;
        const newUrl = ImageGalleryData.filter((item) => {
            return ImageGalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].gImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totallength = ImageGalleryData.length;
        if (cureentIndex === 0) {
            setCureentIndex(totallength - 1);
            const newUrl = ImageGalleryData[totallength - 1].gImage;
            setClickImage(newUrl);
        }
        const newIndex = cureentIndex - 1;
        const newUrl = ImageGalleryData.filter((item) => {
            return ImageGalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].gImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    // console.log(totallength)
    return (
        <>
            {/* /////////////////////////// Section 1 /////////////////////////////////////////// */}
            {/* <section className='Images-Section'>
                <div className='Images-banner-Image'>
                    <div className='Images-Banner-text'>
                    </div>
                </div>
            </section> */}

            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className='Image-Gallery'>
                <div className='Image-Gallery-title'>
                    <h4>Image gallery</h4>
                    <h3>Image gallery</h3>
                </div>
                <div className='container'>
                    <div className='row'>
                        {
                            ImageGalleryData.map((item, index) => {
                                return (
                                    <>
                                        <div className='col-md-4'>
                                                <div className='G-Images' key={index}>
                                                    <img src={item.gImage} alt="" onClick={() => handelClick(item, index)} />
                                                </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {clickImage && (
                <Model
                    clickImage={clickImage}
                    handelRotationRight={handelRotationRight}
                    setClickImage={setClickImage}
                    handelRotationLeft={handelRotationLeft}
                />
            )}
            <Footer />
        </>
    )
}

export default ImageGallery