import React from 'react';
import "../Festival/TheaterFestival.css";
import images01 from "../../Images/Theater Festival/TheaterFestival8.jpg";
import images02 from "../../Images/Theater Festival/TheaterFestival9.jpg";
import images03 from "../../Images/Theater Festival/TheaterFestival7.jpg";
import images04 from "../../Images/Theater Festival/TheaterFestival32.JPG";

import images05 from "../../Images/Theater Festival/TheaterFestival33.JPG";
import images06 from "../../Images/Theater Festival/TheaterFestival6.JPG";
import images07 from "../../Images/Theater Festival/TheaterFestival31.JPG";
import images08 from "../../Images/Theater Festival/TheaterFestival2.JPG";
import FooterComponent from '../FooterComponent';

const TheaterFestival = () => {
    return (
        <>
            <section className='Thater-Festival-Section-1-con'>
                {/* <div className='container'> */}
                    <div className='teater-festival-section-1-heading-con'>

                        <div className='teater-festival-section-1-heading'>
                            <h2 className='headstyle'>Theater Festival</h2>
                        </div>

                        <div className='theater-festival-details-main-con'>

                            <div className='teater-festival-first-con'>
                                <div className='teater-festival-first-details-con'>
                                    <p>Our group is not limited to acting training and theatre shows alone. Since 1990, through our organization, we have been consistently organizing the Gandhi Jayanti Theatre Festival every year. This festival is held annually in Lucknow, Uttar Pradesh, on the 2nd, 3rd, and 4th of October. During the festival, we stage three different plays each year, written and directed by Rajendra Tiwari Sir.</p>
                                </div>
                            </div>

                            <div className='teater-festival-second-con'>
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <div className='teater-festival-images-main-con'>
                                            <div className='teater-festival-first-row-images-con'>
                                                <div className='teater-festival-section-2-image-1'>
                                                    <img src={images01} alt='' />
                                                </div>
                                                <div className='teater-festival-section-2-image-2'>
                                                    <img src={images03} alt='' />
                                                </div>
                                            </div>
                                            <div className='teater-festival-first-row-images-con'>
                                                <div className='teater-festival-section-2-image-2'>
                                                    <img src={images04} alt='' />
                                                </div>
                                                <div className='teater-festival-section-2-image-1'>
                                                    <img src={images02} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='teater-festival-section-2-details-con'>
                                            <div className='teater-festival-section-2-details-info-main-con'>
                                                <p>In this festival, approximately 100 artists participate each year, representing nearly all the states of India. A 15-day training session in Lucknow is equivalent to about a year's worth of learning. Artists from various parts of the country come to Mumbai, and their preparation begins in July, culminating in a show during the 15-day training in Lucknow. This 15-day training is extremely rare and likely not available elsewhere. During these 15 days, artists practice continuously for 20 hours a day on the stage of Gandhi Bhawan Auditorium, leaving only 4 hours for sleep. Artists from Lucknow and surrounding regions also participate directly in Lucknow. During this festival, all artists are awarded certificates, and as a token of remembrance, they are honored with gifts. The Gandhi Jayanti festival is such an event that it is talked about throughout the year.</p>
                                                <p>In addition to Lucknow, our group has also participated in many festivals across different districts of Uttar Pradesh.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='teater-festival-third-con section-3-details'>
                                <div className='teater-festival-third-details-con'>
                                    <p>Our group played a significant role in the Bikaner Theatre Festival held in Bikaner, Rajasthan. Sanjana Kapoor, the owner of Prithvi Theatre, Juhu, Mumbai, was the chief guest at the festival, and Professor Devendra Raj Ankur, Director of NSD, attended for three consecutive days. In addition to the theatrical performances, various seminars were organized, through which all the guests shared their insights and experiences.</p>
                                </div>
                            </div>

                            {/* <div className='teater-festival-second-con'>
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <div className='teater-festival-section-2-details-con'>
                                            <div className='teater-festival-section-4-details-info-main-con'>
                                                <p>Is mahotsav me lagbhag 100 kalakaron pratyek varsh participate karte hain jisme desh ke lagbhag sabhi prant ke kalakar hote hain. 15 din ka lucknow ka prashikshad lagabhag 1 saal seekhen ke barabar hota hai. Mumbai me desh ke  alag alag hisson se sabhi kalakar aate hain aur unki taiyari july se shuru ho jati hai jiska show lucknow me 15 din ke prashikshad ke dauran hota hai. 15 din ka prashikshad bahut hi durlabh hota hai jo shayad kahin aur nahin hota hoga. 15 dinon ke dauran roj lagatar 20 ghante ki practice Gandhi bhawan preksha grah ke stage par hoti hai, sone ke liye sirf 4 ghante hi milte hain. Isme lucknow aur aaspas ke kshotron ke kalakar bhi bhaag lete hain jo direct lucknow aate hain. Is mahotsav ke dauran sabhi kalakaron ko certificate diya jata aur yadgar ke taur par kuchh na kuchh saprem bhet dekar sabhi kalakaon ka samman bhi kiya jata hai. Gandhi jayanti mahotsav aisa mahotsav hai jiski charcha poore saal hoti hai. </p>
                                                <p>Lucknow ke alavan bhi Uttar Pradesh ke alag alag jilon me hamara group bahut sare festivals me bhaag liya hai.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='teater-festival-images-main-con'>
                                            <div className='teater-festival-first-row-images-con'>
                                                <div className='teater-festival-section-2-image-1'>
                                                    <img src={images05} alt='' />
                                                </div>
                                                <div className='teater-festival-section-2-image-2'>
                                                    <img src={images06} alt='' />
                                                </div>
                                            </div>
                                            <div className='teater-festival-first-row-images-con'>
                                                <div className='teater-festival-section-2-image-2'>
                                                    <img src={images07} alt='' />
                                                </div>
                                                <div className='teater-festival-section-2-image-1'>
                                                    <img src={images08} alt='' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                {/* </div> */}
            </section>

            <FooterComponent />
            
        </>
    )
}

export default TheaterFestival