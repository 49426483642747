import React from 'react';
import "../Why/WhyAbss.css";
import Footer from "../FooterComponent";
import image01 from "../../Images/theater.png";
import FacilitiesLogo from "../../Images/Logo.png";

const WhyAbss = () => {
    return (
        <>
            <section className='whyabss-Section'>
                <div className='whyabss-banner-Image'>
                    <div className='whyabss-Banner-text'>
                    </div>
                </div>
            </section>
            <section className="whyabss-section-2">
                <div className='container'>
                    <div className='whyabss-section-main-con'>
                        <div className='whyabss-section-heading-con'>
                            <h3 className='headstyle'>why choose ABSS for theater acting</h3>
                        </div>

                        <div className='whyabss-section-details-con'>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>Our success in your success is our lesson.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>Training in family environment.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>Training by the founder of ABSS.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>No outside teacher teaches in ABSS, here ABSS founder Rajendra Tiwari Sir himself teaches acting, who has 40 years of experience.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>From time to time, the actors trained by ABSS who are successful today come to the class themselves and share their experiences.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>Without any discrimination, lead roles in plays staged by ABSS can be given to students admitted on the same day.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>It is the lowest fee training center providing training by experienced trainers.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>It is our responsibility to educate the students, hence during admission we test your ability.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>We believe in practical more than theory.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>We also selflessly guide and help you to work in films, Ads, web series & serials etc after training.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>Apart from acting, we also keep guiding you from time to time on topics related to social values, behavior and struggle.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>From time to time, auditions are also held in ABSS through which students also get help in casting.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>In ABSS, you are given training in all types of camera acting, theatre, live shows, dubbing etc. so that you do not get scattered anywhere.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>Work in acting is available only through acting talent; hence ABSS tries 100% to give acting talent till the end.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p> ABSS family continues to care for you until you become successful, as long as you do not fail, stick to the example and keep trying in the right direction.</p>
                            </div>
                            <div className='whyabss-section-details'>
                                <div className='whyabss-section-image-con'>
                                    <img src={image01} alt=''/>
                                </div>
                                <p>Even after the completion of the training, we remain available for lifelong suggestions and support.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

                     

            {/* ////////////////////////////////////////// Section Facilities /////////////////////////////////// */}

            <section className='Section-Facilities'>
                <div className='container'>
                    <div className='row'>
                        <div className='Facilities-main-heading'>
                            <h5>Facilities</h5>
                            <h4>Facilities</h4>
                        </div>

                        <div className='col-md-3 col-sm-6'>
                            <div className='Facilities-Four'>
                                <div className='Facilities-Container'>
                                    <div className='Facilities-logo'>
                                        <img src={FacilitiesLogo} alt="" />
                                    </div>
                                    <div className='Facilities-Text'>
                                        <h5>Live Theater Shows</h5>
                                        <p>ABSS offer live theater for theater education that helps students carve their own career path.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 col-sm-6'>
                            <div className='Facilities-Four'>
                                <div className='Facilities-Container'>
                                    <div className='Facilities-logo'>
                                        <img src={FacilitiesLogo} alt="" />
                                    </div>
                                    <div className='Facilities-Text'>
                                        <h5>Short Films</h5>
                                        <p>The ABSS has always been proud to treat short films .Short films have and will continue to be an important part of cinema, storytelling, and culture.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 col-sm-6'>
                            <div className='Facilities-Four'>
                                <div className='Facilities-Container'>
                                    <div className='Facilities-logo'>
                                        <img src={FacilitiesLogo} alt="" />
                                    </div>
                                    <div className='Facilities-Text'>
                                        <h5>Camera Audition</h5>
                                        <p>The ABSS student housing offers community, safety, convenience and an invaluable total immersion experience for training and living.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3 col-sm-6'>
                            <div className='Facilities-Four'>
                                <div className='Facilities-Container'>
                                    <div className='Facilities-logo'>
                                        <img src={FacilitiesLogo} alt="" />
                                    </div>
                                    <div className='Facilities-Text'>
                                        <h5>Photo Portfolio</h5>
                                        <p>We choose the highest quality food to fuel our students and staff that encompasses a wide variety of fresh, natural, unadulterated foods.    </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>



            <Footer />
        </>
    )
}

export default WhyAbss