import React from 'react';
import "../CSS/Faculty.css"
// import demo from "../Images/AlumniImage010.jpg"
import Facultydata from '../Component/Facultydata';
import FooterComponent from '../Component/FooterComponent';
import { AiFillInstagram } from 'react-icons/ai';
import { GrFacebookOption } from 'react-icons/gr';
import { AiOutlineTwitter } from 'react-icons/ai';

const Faculty = () => {
  return (
    <>

      {/* /////////////////////////////////////////// section 1 //////////////////////////////////////////// */}

      <section className='Faculty-Section01'>
        <div className='Faculty-Main-Heading'>
          <h1>
            Faculty
          </h1>
        </div>
      </section>

      {/* /////////////////////////////////////////// section 2//////////////////////////////////////////// */}
      <section className='Faculty-Section2'>
        <div className='container'>
          <div className='row'>
            {
              Facultydata.map((e) => {

                return (
                  <>
                  <div class="col-md-3 col-sm-6">
                    <div class="profile-card">
                      <div class="profile-img">
                        <img src={e.FImage} alt="image" />
                        <div class="over-layer">
                          <p>{e.Fdescription}</p>
                        </div>
                      </div>
                      <div class="profile-content">
                        <h3 class="title">Bayley<span>Designer</span></h3>
                        <ul class="social-link">
                          <li><a href="" class="fa fa-facebook"><AiFillInstagram/></a></li>
                          <li><a href="" class="fa fa-twitter"><GrFacebookOption/></a></li>
                          <li><a href="" class="fa fa-youtube"><AiOutlineTwitter/></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                    
                  </>
                )
              })
            }


          </div>

        </div>

      </section>

      <FooterComponent />


    </>
  )
}

export default Faculty