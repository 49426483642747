import actingcourses from "../Images/HomeClass04.jpg";
import actingcourses01 from "../Images/D_in_AImage01.jpg";
import actingcourses02 from "../Images/D_in_AImage02.jpg";


const CoursesData = [
    {
        slugs: "/acting-course",
        heading: "Acting Course",
        mainImage:actingcourses,
        texth1: "Acting Course",
        image01: actingcourses,
        image02: actingcourses,
        image03: actingcourses,
        para01: "Film and media represent an exceptional field where diligent efforts can pave the way to achieving remarkable success. At our academy, we offer a comprehensive acting course that covers everything from basic to advanced techniques, along with practical experience in theater stage plays.",
        para02: "Our acting course entails daily sessions focusing on various aspects to facilitate easy practice. Mastery of all facets of acting is imperative for embodying any character effectively, which is why we emphasize daily practice across all subjects. Acting is both simple and complex; however, with dedicated attention to performance, daily practice becomes second nature. Initially, students engage in foundational work, tackling different subjects daily, before progressing to comprehensive integration of all aspects.",
        para03: "Through theater, students learn the art of character portrayal, honing their skills in characterization. Subsequently, they transition to camera performance, where they acquire knowledge about all camera components and undergo practical exercises to master each shot.",
        para04: "Our curriculum ensures a holistic understanding of acting, blending theoretical knowledge with practical application. By providing hands-on experience and personalized guidance, we equip students with the skills and confidence necessary to excel in the dynamic world of acting.",
        para05: "Film and media represent an exceptional field where diligent efforts can pave the way to achieving remarkable success. At our academy, we offer a comprehensive acting course that covers everything from basic to advanced techniques, along with practical experience in theater stage plays.",
        para06: "Our acting course entails daily sessions focusing on various aspects to facilitate easy practice. Mastery of all facets of acting is imperative for embodying any character effectively, which is why we emphasize daily practice across all subjects. Acting is both simple and complex; however, with dedicated attention to performance, daily practice becomes second nature. Initially, students engage in foundational work, tackling different subjects daily, before progressing to comprehensive integration of all aspects.",
    },
    {
        slugs: "/theater-group",
        heading: "Theater Group",
        mainImage: actingcourses01,
        texth1: "",
        image01: "",
        image02: "",
        image03: "",
        para01: "In our theatre program, we delve into every aspect of the art form, encompassing meditation, imagination, improvisation, observation, facial expressions, body language, diction, voice modulation, camera techniques, and more. ",
        para02: "Our daily sessions involve intensive practice of theatre plays based on scripts, supplemented by activities such as story reading, character analysis, and psychological exploration. Students engage in character development through blocking rehearsals, focusing on body language, reactions, emotions, and costume-props to bring authenticity to their performances.",
        para03: "Within just three months, students get the opportunity to showcase their talents in live theatre shows held in auditoriums. To enhance performance quality, we prioritize energy level enhancement, breathing techniques, and sound practice, ensuring holistic improvement from head to stomach.",
        para05: "Rehearsals are tailored to mimic camera patterns, preparing students for both stage and screen performances. Our reach extends beyond Mumbai, with shows staged in various cities across India. Additionally, we host an annual theatre festival, the Gandhi Jayanti Natya Mahotsav, providing a platform for aspiring actors to display their skills and celebrate the rich cultural heritage of theatre.",
        para06: "Join us on this enriching journey where creativity flourishes, skills are honed, and dreams take center stage. Our theatre class is designed to nurture talent, foster artistic expression, and instill a deep appreciation for the transformative power of theatre in all its forms.",
    },
    {
        slugs: "/camera-courses",
        heading: "Camera Courses",
        mainImage: actingcourses02,
        texth1: "",
        image01: "",
        image02: "",
        image03: "",
        para01: "Our camera curriculum is designed to provide advanced-level training, focusing on equipping students with the skills to perform confidently in front of the camera. Keeping pace with the evolving film industry, our aim is to educate individuals on working collaboratively with camera, sound, and lighting setups.",
        para02: "Our efforts are consistently geared towards offering training based on the latest techniques. Our experienced instructors, who have worked in directorial roles in television and film, guide students through practical exercises, including shooting short films outdoors, to ensure comprehensive learning experiences.",
        para03: "We not only teach children the art of shooting short films but also emphasize audition preparation and techniques right from the start. Additionally, we pay attention to facial expressions, voice modulation, dialogue delivery, and diction.",
        para04: "Our camera course offers practical sessions in front of the camera, along with advanced acting knowledge. Students receive training in audition practices and learn how to create camera-recorded auditions. They also benefit from lifelong updates on audition opportunities and receive assistance in promoting themselves to production houses and casting directors.",
        para05: "During camera classes, students concentrate on character immersion, improvisation, and adapting body language according to character requirements. They learn to manipulate voice graph and dialogue variations, focusing on pronunciation and diction in Hindi, regional languages, and Urdu. Emphasis is also placed on face expressions, emotions, reactions, and character portrayal in different moods.",
        para06: "Moreover, our curriculum covers technical aspects such as camera handling, facial control, and audition video and showreel production. Students are prepared for various shooting scenarios, including short films, web series, films, and television shows.",
        para07: "Our camera course durations range from 1 month to 6 months, with classes scheduled from Monday to Sunday, with Wednesday as the designated holiday. The course is open to individuals aged 15 and above, offering comprehensive training for aspiring actors in the intricacies of camera acting.",
    },
    {
        slugs: "/acting-workshop",
        heading: "Acting Workshop",
        mainImage: actingcourses,
        texth1: "",
        image01: "",
        image02: "",
        image03: "",
        para01: "Every week, we host a workshop where participants delve into all aspects of acting. Understanding the intricacies of this field is crucial before stepping into the world of acting, and once understood, the journey becomes more manageable. In our workshop, actors, directors, producers, writers, and technical teams from the film and television industry provide guidance on various aspects.",
        para02: "Renowned Bollywood actors and directors also conduct workshops, enriching participants with their insights and expertise. Casting directors offer valuable advice on navigating the industry, which proves invaluable as individuals embark on their acting careers.",
        para03: "The workshop covers a wide range of subjects, including character immersion, improvisation, body language alignment with character traits, voice modulation, pronunciation, and diction in multiple languages. Participants learn to convey emotions through facial expressions, reactions, and character portrayal in different moods.",
        para05: "Moreover, the workshop focuses on enhancing energy levels, breathing techniques, and sound practices. Technical details such as camera handling, facial control, audition preparation, and showreel production are also thoroughly covered. Participants gain practical experience through monologue shoots, portfolio shoots, and audition simulations, preparing them for various opportunities in the industry.",
        para06: "Our workshops provide a platform for aspiring actors to learn from industry professionals, refine their skills, and gain valuable insights into the world of acting. With comprehensive training and guidance, participants are equipped to pursue their dreams and succeed in the competitive field of entertainment.",
    },
    {
        slugs: "/classes-for-children",
        heading: "Classes For Children",
        mainImage: actingcourses02,
        texth1: "",
        image01: "",
        image02: "",
        image03: "",
        para01: "In today's era, acting is being increasingly recognized as a viable career option, with a growing enthusiasm among children. It brings me immense joy to facilitate acting classes for children, as I firmly believe in nurturing their talents and empowering them to excel in any field they choose, whether it be film, television, advertising, or beyond.",
        para02: "Under the guidance of Rajendra Tiwari himself, children are taught in a friendly and supportive manner, making the learning process enjoyable and accessible.",
        para03: "Our course offerings include monthly, 3-month, 6-month, and lifetime memberships, with classes scheduled on weekdays (Monday to Saturday) and weekends (Saturday and Sunday). The course is tailored for children aged 3 to 12 years.",
        para04: "The curriculum covers a wide array of subjects, ranging from basic to advanced acting techniques, practical camera skills, theatre practice, audition preparation, and promotion strategies to reputable production houses and casting directors.",
        para05: "Children delve into various topics such as meditation, imagination, physical exercises, character immersion, improvisation, storytelling, speech, observation, body language alignment, voice modulation, pronunciation, and diction in multiple languages.",
        para06: "Additionally, they learn about facial expressions, emotions, reactions, character portrayal in different moods, energy level enhancement, breathing techniques, and sound practices. They also engage in theatre workshops, play rehearsals, live stage performances, street plays, and more.",
        para07: "Technical aspects such as camera handling, facial control, audition preparation, showreel production, monologue shoots, portfolio shoots, and audition simulations are thoroughly covered, preparing children for various opportunities in the industry.",
        para08: "Our children's acting classes provide a nurturing environment where young talents can thrive, develop confidence, and explore their creativity while acquiring essential skills for a successful acting career.",
    },
    {
        slugs: "/online-acting-courses",
        heading: "Online Acting Courses",
        mainImage: actingcourses01,
        texth1: "",
        image01: "",
        image02: "",
        image03: "",
        para01: "Learning acting online has become more accessible than ever before. While some may doubt the efficacy of online learning for acting, it's precisely the opposite. In today's digital age, skilled instructors who offer practical training online are scarce. Here, Rajendra Tiwari himself conducts online acting classes, providing hands-on practical guidance. Through consistent practice, significant improvements become evident, often within just one month.",
        para02: "Our online acting courses offer various durations, including 1 month, 3 months, 6 months, and lifetime memberships. Classes are scheduled on weekdays (Monday to Friday) and weekends (Saturday and Sunday), catering to individuals aged 7 years and above.",
        para03: "The curriculum covers a wide range of topics, from basic to advanced acting techniques, knowledge of performing in front of the camera, and insights into theatre. Participants learn audition techniques, including practice sessions and creating camera-recorded auditions, along with guidance on promotion to production houses and casting directors.",
        para04: "Subjects covered in the syllabus include meditation, imagination, physical exercises, concentration on character immersion, improvisation, storytelling, speech, observation, body language alignment with character traits, face and body movements, voice modulation, pronunciation, and diction in multiple languages.",
        para05: "Additionally, participants learn about facial expressions, emotions, reactions, characterization, performances in various moods, energy level enhancement, breathing techniques, and sound practices. They also engage in theatre workshops, play rehearsals, and live stage performances.",
        para06: "Technical aspects such as camera handling, facial control, audition preparation, showreel production, monologue shoots, portfolio shoots, and audition simulations are thoroughly covered, preparing participants for a successful acting career.",
        para07: "Our online acting classes provide a comprehensive learning experience, blending theoretical knowledge with practical application, and empowering individuals to pursue their passion for acting from the comfort of their own homes.",
    },


];

export default CoursesData;