import React from 'react'
import "../CSS/Events.css"
import EventsData from '../Component/EventsData';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from "../Component/FooterComponent"
import { FaRegCalendarAlt } from "react-icons/fa";
import { Image } from 'antd';

const Events = () => {

    const [eventData, setEventdata] = useState(EventsData)
    const [dataId, setDataId] = useState("")
    console.log(eventData)

    const Function01 = (index, item) => {
        setDataId(index.id)
        console.log(index.id)
    }


    return (
        <>
            {/* /////////////////////////////////////////// section 1 //////////////////////////////////////////// */}

            <section className='Event-Section01'>
                <div className='Events-Main-Heading'>
                    <h1>
                        News & Events
                    </h1>
                </div>
            </section>

            {/* /////////////////////////////////////////// section 2 //////////////////////////////////////////// */}

            <section className='Events-Section02'>
                <div className='container'>
                    <div className='row'>
                        {
                            eventData.map((item, index) => {
                                const { id, EImage, EMDescription, Date } = item;
                                console.log(item)
                                return (
                                    <>
                                        <div className='col-md-6'>
                                            <div className='Events-card' key={id}>
                                                <div className='Events-card-body' >
                                                    <div className='Events-card-Image'>
                                                        <Image src={EImage} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                    </div>
                </div>
            </section>

            <Footer />

        </>
    )
}

export default Events