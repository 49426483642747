import "../Faculty/ArunShekhar.css";
import ArunShekharImage from "../../Images/ABSS Teachers/Arun Shekhar.jpeg";
const ArunShekhar = (()=>{
    return(
        <>
         <section className='AshwiniShukla-Section'>
                <div className='AshwiniShukla-banner-Image'>
                    <div className='AshwiniShukla-Banner-text'>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-2'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section2-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='rajendra-tiwari-Section2-Image-con'>
                                    <div className='rajendra-tiwari-Section2-Image'>
                                        <img src={ArunShekharImage} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='rajendra-tiwari-Section2-details-con'>
                                    <div className='rajendra-tiwari-Section2-details-head'>
                                        <h3 className='rajendra-tiwari-text-style'>Arun Shekhar</h3>
                                    </div>
                                    <p>Arun Kumar Singh, also known as Arun Shekhar is an accomplished professional with 30+ years
of experience in diverse roles and undertakings. Arun is a multi-talented individual who wears
many hats. Highly skilled and experienced professional seeking an administrative government
job. Proven abilities in writing, radio hosting, voice-over artistry, and language training.
Dedicated to delivering excellence in all tasks, with a passion for excelling in a given job
position/role.</p>
                                </div>
                                {/* <div className='rajendra-tiwari-Section2-details-con'>
                                    <div className='rajendra-tiwari-Section2-details-head'>
                                        <h3 className='rajendra-tiwari-text-style'>Professional Journey</h3>
                                    </div>
                                    <p>Ashwani began his career with a focus on theatre and quickly rose to prominence as a key figure at ABSS Acting and Theatre Group. His expertise in management has been instrumental in the group’s success, providing guidance and support to newcomers eager to break into the film industry.</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
});

export default ArunShekhar;