            import React from 'react'
import "../CSS/VideoGallery.css";
import ReactPlayer from 'react-player';

import Footer from "../Component/FooterComponent"

const VideoGallery = () => {
    return (
        <>
            {/* /////////////////////////// Section 1 /////////////////////////////////////////// */}
            {/* <section className='Video-Section'>
                <div className='Video-banner-Image'>
                    <div className='Video-Banner-text'>
                    </div>
                </div>
            </section> */}

            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className='Video-Gallery'>
                <div className='Video-title'>
                    <h4>Video Gallery</h4>
                    <h3>Video Gallery</h3>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=3hAD_WJe2f4&t=55s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=BlFiiDEnHso&t=63s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=UiSCpuEl7Xo&t=21s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://youtu.be/W2-ycWozznU" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=2DMm7VaCraA&t=52s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=MExgstqJ_kI" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=oGIzUC2Vk5A" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=qcBpAEvZl8Q" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=6ApWhrEmr0Q" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=sYjXom4RGjU" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=vDEzTRDN5oQ&t=44s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://youtu.be/6ApWhrEmr0Q" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=2QaltnlAftU" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=0uzBsbO8U4I&t=19s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=7LmpUHTTmac" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=K1LTd_duNQI&t=893s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=u2qKMbaE3fc&t=606s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=pZJnn8BxMck&t=35s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=vSl7LfTtgc4&t=66s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=0uzBsbO8U4I&t=19s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=9WgB5m9qyOQ" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=7hiT3d5MMhA&t=2s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=Yk0Up2OCEZs&t=3s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=uA5bylUMCJc&t=48s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=GGep78SON34" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://youtu.be/FBix_3lPCT4" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=popKu-6Inao&t=10s" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=JUm7tQS2NK8" />
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='G-Video'>
                                <ReactPlayer controls width='100%' height='100%' borderRadius="5px" url="https://www.youtube.com/watch?v=vSu5SgCfeeM&t=1574s" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Footer />


        </>
    )
}

export default VideoGallery