import React from 'react'
import "../CSS/Alumni.css"

import { useState } from "react";

import { ImLink } from 'react-icons/im';
import Footer from "../Component/FooterComponent";
import AlumniData from "../Component/AlumniData"


const Alumni = () => {
    // const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [alumni, setAlumni] = useState(AlumniData)
    return (
        <>

            {/* /////////////////////////// Section 1 /////////////////////////////////////////// */}
            <section className='Alumni-Section'>
                <div className='Alumni-banner-Image'>
                    <div className='Alumni-Banner-text'>
                    </div>
                </div>
            </section>

            {/* //////////////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className='Alumni-Notable-Section2' >
                <div className='AN-Banner'>
                    <div className='Alumini-S2-Heading'>
                        <h4>CHECK IT OUT</h4>
                    </div>
                    <div className='Alumini-S2-Description'>
                        <h3>Have a fab time acting <br></br> to the beat of the past</h3>
                    </div>
                </div>

            </section>

            {/* //////////////////////////////////// Section 3 /////////////////////////////////////////// */}


            <section className='AlumniSection3'>
                <div className='AlumniTitle'>
                    <h5>Alumni</h5>
                    <h4>Alumni</h4>

                </div>
                <div className='container'>
                    <div className='row'>

                        {
                            alumni.map((ele) => {
                                const { Name, Image, MediaLink } = ele
                                return (
                                    <>
                                        <div className='col-lg-3 col-md-4 col-sm-6'>
                                            <a href={MediaLink} target="_blank">
                                                <div class="my-card">
                                                    <div class="card-content">
                                                        <div class="img-place">
                                                            <div id="card-img">
                                                                <img src={Image} alt="" />
                                                            </div>
                                                            <div class="card-text">
                                                                <h5>{Name}</h5>
                                                                {/* <span> <ImLink/></span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </>
                                )
                            })
                        }

                    </div>
                </div>
            </section>





            <Footer />

        </>
    )
}

export default Alumni