import demoprofile from "../Images/textimonial demo image.png";

import GaneshPai from "../Images/Alumni/Ganesh Pai.jpeg";
import RudhrakshJaiswal from "../Images/Alumni/Rudhraksh Jaiswal.jpeg";
import RobinSohi from "../Images/Alumni/Robin Sohi.jpeg";
import HarveerSingh from "../Images/Alumni/Harveer Singh.jpeg";
import AnshuVarshney from "../Images/Alumni/Anshu Varshney.jpeg";
import NikhleshRathore from "../Images/Alumni/Nikhlesh Rathore.jpeg";
import SheetalTiwari from "../Images/Alumni/Sheetal Tiwari.jpeg";
import PalakRana from "../Images/Alumni/Palak Rana.jpeg";
import KushalKanani from "../Images/Alumni/Kushal Kanani.jpeg";
import RaviChhabra from "../Images/Alumni/Ravi Chhabra.jpeg";
import KabeerBishnoi from "../Images/Alumni/Kabeer Bishnoi.jpeg";
import PradeepNigam from "../Images/Alumni/Pradeep Nigam.jpeg";
import PrashantGoswami from "../Images/Alumni/Prashanth Goswami.jpeg";
import GulshanGautam from "../Images/Alumni/Gultesham Khan.jpeg";
import GopalGemini from "../Images/Alumni/Gopal Gemini.jpeg";
import ArnavBohra from "../Images/Alumni/Arnav Bohra.jpeg";
const TestimonialData = [
    {
        id: 1,
        Name: "Ganesh Pai",
        Discription01:"Now that it's 2024, let's go. My journey in the ABSS family began in 2011. It's been almost 13 years, but it seems like just yesterday. At that time it was the initial day of ABSS in Mumbai and we were only 4-5 people in this family and today I see that the ABSS family has grown a lot. In this journey of 13 years, I have seen the hard work and dedication of Mr. Rajendra Tiwari. That's why the group is at this stage today, and it's just the beginning. In the future, it will be the no. 1 group. You will find very few such gurus in Mumbai who can teach you such knowledge. Yes, he has wealth; he just wants someone to steal it. I learned a lot from him; he was like that then and even today there is a lot to learn. Even today I would like to remake Theater and empty the coffers of the gentleman, but I could not get a job because of my commitment. I'm here, but I'll definitely dance with you all one more time. From then till now my relationship with Siri, Bhabhi, Ashwin and Chinki has been like a family member... I would like to say to the new students that you all are in the right place. Just don't stop working hard; what you want will happen. I say again: There is a treasure nearby sir; rob it; you all will not get this chance again... Sir, treasure is robbed only by those who work, not by those who steal work. Sir, thank you very much. because The audio game I created is here Yes, the treasure is in front of you. That's all I'm saying.",
        Discription02:"Congratulations, ABSS family.",
        Image: GaneshPai,
    },
    {
        id: 2,
        Name: "Rudhraksh Jaiswal",
        Discription01:"ABSS was my first acting school, and it has helped me reach amazing heights today. I've been learning acting in Abss since 2015, when I was 12 years old. Rajendra Tiwari sir's teachings are still useful to us now when performing in front of a camera. Sir has prepared us so that we can travel anyplace and deliver our finest performance. ABSS is the ideal venue for learning acting",
        Image: RudhrakshJaiswal,
    },
    {
        id: 3,
        Name: "Robin Sohi",
        Discription01:"Best theatre group in Mumbai they help you to create new you, and help you to be comfortable with camera. Here you get to learn something new day after day. Best teacher Rajendra Tiwari sir.",
        Image: RobinSohi,
    },  
    {
        id: 4,
        Name: "Harveer Singh",
        Discription01:"Abss Theater Group is an institute where you are taught the art of practical living along with acting. To be honest, this is the Gurukul where you develop the most in acting.",
        Image: HarveerSingh,
    },  
    {
        id: 4,
        Name: "Anshu Varshney",
        Discription01:"I had an amazing experience with this group. This was my first Theater class in Mumbai. I always learn new skills every day. Rajendra Sir rocks.",
        Image: AnshuVarshney,
    },  
    {
        id: 4,
        Name: "Nikhlesh Rathore",
        Discription01:"Rajendra Sir does not consider anyone new or old; you get an equal opportunity to learn everything in ABSS. The more you stay connected with Sir in ABSS, the more you get to learn. You can get training similar to NSD here in the workshop in Lucknow. Sir, you can never forget what I taught you.",
        Image: NikhleshRathore,
    },  
    {
        id: 4,
        Name: "Sheetal Tiwari",
        Discription01:"Rajendra Tiwari, sir, is a very good director and a true guru. Very few such gurus are found. I had never learned acting, and sir made us prepare 2-2 characters in 15 days. Rajendra Tiwari, sir, is an ocean of acting. You can learn as much as you want from Sir, and you will never fall short. The experience with Sir will be very memorable.",
        Image: SheetalTiwari,
    },  
    {
        id: 4,
        Name: "Palak Rana",
        Discription01:"My experience with ABSS is amazing. The environment here is exactly like a family, and the students here also live like friends. Here, I learned everything about humanity from Tiwari sir, which was very challenging and very difficult. There is a lot more fun in learning by challenging oneself. The ability to learn in difficult situations is something we really liked about ABSS.",
        Image: PalakRana,
    },  
    {
        id: 4,
        Name: "Kushal Kanani",
        Discription01:"I wanted to take a moment to express my deepest gratitude for the incredible journey I've experienced during my time at your esteemed institution. The opportunity to immerse myself in the world of acting under your guidance has been nothing short of life-changing.",
        Discription02:"Thank you for providing a nurturing environment where I could explore, learn, and grow not only as an actor but also as a human being. The challenges, triumphs, and countless lessons along the way have shaped me in ways I never imagined possible.",
        Discription03:"Your dedication to fostering creativity, nurturing talent, and instilling a deep understanding of the craft has been truly inspiring. From the supportive faculty to the passionate fellow students, every aspect of my experience at ABSS has been enriching and empowering.",
        Discription04:"As I move forward in my journey, I carry with me the invaluable lessons, unforgettable memories, and profound realization of the true meaning of acting that I've gained from my time at your institution.",
        Discription05:"Thank you once again for everything. I am forever grateful for the role ABSS and GURUJI have played in shaping my future as an actor and as a person",
        Image: KushalKanani,
    },  
    {
        id: 4,
        Name: "Ravi Chhabra",
        Discription01:"ABSS is like a 2nd family for me. i ve learnt so much about my acting craft and how to stay motivate all time in this bollywood industry. it’s my pleasure to be a part of abss theatre group. thank  you so much rajendra tiwari sir .",
        Image: RaviChhabra,
    },  
    {
        id: 4,
        Name: "Kabeer Bishnoi",
        Discription01:"ABSS is not only an acting intuitive to me; it was a whole, basic life training platform. I learned all my amazing acting skills from Guru Ji Rajendra Tiwari sir, from whom I was able to make a career in the acting field, molding my inner soul into my outer presence.",
        Image: KabeerBishnoi,
    },  
    {
        id: 4,
        Name: "Pradeep Nigam",
        Discription01:"If you want to start your acting career, ABSS is the best institute for that, which teaches you the basics of acting skills and also gives you a Theater stage to showcase your talent.",
        Image: PradeepNigam,
    },  
    // {
    //     id: 4,
    //     Name: "Dev Kumar (Anil Kumar)",
    //     Discription01:"To achieve any destination, it is important to know the right path, ABSS shows you that right path, from here you will know the way to be successful in this field along with acting, when I came here in 2015, I I only knew that I had to do acting, but I got to learn many other things, the entire credit for which goes to Rajendra Tiwari ji, thank you very much.",
    //     Image: demoprofile,
    // },  
    {
        id: 4,
        Name: "Prashant Goswami",
        Discription01:"Theatre was my foundational stone in this industry, and I'm proud to admit that one of my earliest Theater experiences, bountiful of myriad lessons both on acting and life, was at A.B.S.S. Theatre Group, a prestigious commune headlined by my fatherly figure Shri Rajendra Tiwari Ji. I in earnest encourage any aspiring actor to give this a try if you want to introduce yourself to what acting is in its essence.",
        Image: PrashantGoswami,
    },  
    {
        id: 4,
        Name: "Gulshan Gautam",
        Discription01:"Hi, my name is Gulshan Gautam. I belong to Meerut, Uttar Pradesh. I came to Mumbai in 2015, where I joined Theater or acting classes. Then I came to ABS, which is the best Theater and acting institute in our country. Mr. . Rajender Tiwari sir teaches acting very well; my experience is good; I am happy in our Theater group ABSS; I am a student of ABSS Theater group. ",
        Discription02:"Our abs Theater group is the is the best acting institute in Mumbai.",
        Image: GulshanGautam,
    },  
    {
        id: 4,
        Name: "Gopal Gemini",
        Discription01:"Any artist who wants to go into the acting line... I would just say that our Guruji Shri Rajendra Tiwari ji is the ocean of the acting world, in which if you jump once, you will emerge as a great actor whom no one can stop from moving forward.",
        Image: GopalGemini,
    },  
    {
        id: 4,
        Name: "Arnav Bohra",
        Discription01:"If you are seriously willing to learn acting, ABSS is the right place for you. Here, you will learn everything you need to become a great actor. From basics to advanced, Rajendra Sir helps you all the way. He teaches you in such a practical way that I have never seen anywhere. All my gratitude to Rajendra Sir for guiding me in every aspect.",
        Image: ArnavBohra,
    },  
];
export default TestimonialData;