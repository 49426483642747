// Alumni Images 
import GaneshPai from "../Images/Alumni/Ganesh Pai.jpeg";
import AmrapaliGupta from "../Images/Alumni/Amrapali Gupta.jpeg";
import RudhrakshJaiswal from "../Images/Alumni/Rudhraksh Jaiswal.jpeg";
import AnshuVarshney from "../Images/Alumni/Anshu Varshney.jpeg";
import RobinSohi from "../Images/Alumni/Robin Sohi.jpeg";
import SheetalTiwari from "../Images/Alumni/Sheetal Tiwari.jpeg";
import PalakRana from "../Images/Alumni/Palak Rana.jpeg";
import NandiniTiwari from "../Images/Alumni/Nandini Tiwari.jpeg";
import RiddhiDingoria from "../Images/Alumni/Riddhi Dingoria.jpeg";
import DeveshSharma from "../Images/Alumni/Devesh Sharma.jpeg";
import AishanaSingh from "../Images/Alumni/Aishana S Singh.jpeg";
import HarveerSingh from "../Images/Alumni/Harveer Singh.jpeg";
import NikhleshRathore from "../Images/Alumni/Nikhlesh Rathore.jpeg";
import KushalKanani from "../Images/Alumni/Kushal Kanani.jpeg";
import GulteshamKhan from "../Images/Alumni/Gultesham Khan.jpeg";
import PradeepNigam from "../Images/Alumni/Pradeep Nigam.jpeg";
import NehikaSinghRajput from "../Images/Alumni/Nehika Singh Rajput.jpeg";
import NairaNehalShah from "../Images/Alumni/Naira Nehal Shah.jpeg";
import MandeepSingh from "../Images/Alumni/Mandeep Singh.jpeg";
import RaviChhabra from "../Images/Alumni/Ravi Chhabra.jpeg";
import PrashanthGoswami from "../Images/Alumni/Prashanth Goswami.jpeg";
import AyushGupta from "../Images/Alumni/Ayush Gupta.jpeg";
import KabeerBishnoi from "../Images/Alumni/Kabeer Bishnoi.jpeg";
import SandeepBhojak from "../Images/Alumni/Sandeep Bhojak.jpeg";
import MahimaGupta from "../Images/Alumni/Mahima Gupta.jpeg";
import AlfiyaKhan from "../Images/Alumni/Alfiya Khan.jpeg";
import AyushiTiwari from "../Images/Alumni/Ayushi Tiwari.jpeg";
import HunzaSabir from "../Images/Alumni/Hunza Sabir.jpeg";
import NivaSahay from "../Images/Alumni/Niva Sahay.jpeg";
import GopalGemini from "../Images/Alumni/Gopal Gemini.jpeg";
import KajalSingh from "../Images/Alumni/Kajal Singh.jpeg";
import NidhiGangta from "../Images/Alumni/Nidhi Gangta.jpeg";
import AdarshCheema from "../Images/Alumni/Adarsh Cheema.jpeg";
import AnupriyaParmar from "../Images/Alumni/Anupriya Parmar.jpeg";
import ArnavBohra from "../Images/Alumni/Arnav Bohra.jpeg";
import LaxmiKushwaha from "../Images/Alumni/Laxmi Kushwaha.jpeg";
import RajveerDey from "../Images/Alumni/Rajveer Dey.jpeg";
import RanveerSingh from "../Images/Alumni/Ranveer Singh.jpeg";
import SarveshGupta from "../Images/Alumni/Sarvesh Gupta.jpeg";
import TusharChawla from "../Images/Alumni/Tushar Chawla.jpeg";

const AlumniData = [
    {
        id:1,
        Name:"Ganesh Pai",
        alt:"Ganesh Pai",
        Image:GaneshPai,
    },
    {
        id:2,
        Name:"Amrapali Gupta",
        alt:"Amrapali Gupta",
        Image:AmrapaliGupta,
    },
    {
        id:3,
        Name:"Rudhraksh Jaiswal",
        alt:"Rudhraksh Jaiswal",
        Image:RudhrakshJaiswal,

    },
    {
        id:4,
        Name:"Anshu Varshney",
        alt:"Anshu Varshney",
        Image:AnshuVarshney,

    },
    {
        id:5,
        Name:"Robin Sohi",
        alt:"Robin Sohi",
        Image:RobinSohi,

    },
    {
        id:6,
        Name:"Sheetal Tiwari",
        alt:"Sheetal Tiwari",
        Image:SheetalTiwari,

    },
    {
        id:7,
        Name:"Palak Rana",
        alt:"Palak Rana",
        Image:PalakRana,

    },
    {
        id:8,
        Name:"Nandini Tiwari",
        alt:"Nandini Tiwari",
        Image:NandiniTiwari,

    },
    {
        id:9,
        Name:"Riddhi (Raaya) Dingoria",
        alt:"Riddhi (Raaya) Dingoria",
        Image:RiddhiDingoria,

    },
    {
        id:10,
        Name:"Devesh Sharma",
        alt:"Devesh Sharma",
        Image:DeveshSharma,

    },
    {
        id:11,
        Name:"Aishana S Singh",
        alt:"Aishana S Singh",
        Image:AishanaSingh,

    },
    {
        id:12,
        Name:"Harveer Singh",
        alt:"Harveer Singh",
        Image:HarveerSingh,

    },
    {
        id:13,
        Name:"Nikhlesh Rathore",
        alt:"Nikhlesh Rathore",
        Image:NikhleshRathore,

    },
    {
        id:14,
        Name:"Kushal Kanani",
        alt:"Kushal Kanani",
        Image:KushalKanani,

    },
    {
        id:15,
        Name:"Gultesham Khan",
        alt:"Gultesham Khan",
        Image:GulteshamKhan,

    },
    {
        id:16,
        Name:"Pradeep Nigam",
        alt:"Pradeep Nigam",
        Image:PradeepNigam,

    },
    {
        id:17,
        Name:"Nehika Singh Rajput",
        alt:"Nehika Singh Rajput",
        Image:NehikaSinghRajput,

    },
    {
        id:18,
        Name:"Naira Nehal Shah",
        alt:"Naira Nehal Shah",
        Image:NairaNehalShah,
    },
    {
        id:19,
        Name:"Mandeep Singh",
        alt:"Mandeep Singh",
        Image:MandeepSingh,

    },
    {
        id:20,
        Name:"Ravi Chhabra",
        alt:"Ravi Chhabra",
        Image:RaviChhabra,

    },
    {
        id:21,
        Name:"Prashanth Goswami",
        alt:"Prashanth Goswami",
        Image:PrashanthGoswami,

    },
    {
        id:22,
        Name:"Ayush Gupta",
        alt:"Ayush Gupta",
        Image:AyushGupta,

    },
    {
        id:23,
        Name:"Kabeer Bishnoi",
        alt:"Kabeer Bishnoi",
        Image:KabeerBishnoi,

    },
    // {
    //     id:24,
    //     Name:"Sandeep Bhojak",
    //     alt:"Sandeep Bhojak",
    //     Image:SandeepBhojak,
    // },
    {
        id:25,
        Name:"Mahima Gupta",
        alt:"Mahima Gupta",
        Image:MahimaGupta,
    },
    // {
    //     id:26,
    //     Name:"Alfiya Khan",
    //     alt:"Alfiya Khan",
    //     Image:AlfiyaKhan,
    // },
    {
        id:27,
        Name:"Ayushi Tiwari",
        alt:"Ayushi Tiwari",
        Image:AyushiTiwari,
    },
    {
        id:28,
        Name:"Hunza Sabir",
        alt:"Hunza Sabir",
        Image:HunzaSabir,
    },
    // {
    //     id:29,
    //     Name:"Niva Sahay",
    //     alt:"Niva Sahay",
    //     Image:NivaSahay,
    // },
    {
        id:30,
        Name:"Gopal Gemini",
        alt:"Gopal Gemini",
        Image:GopalGemini,
    },
    // {
    //     id:32,
    //     Name:"Kajal Singh",
    //     alt:"Kajal Singh",
    //     Image:KajalSingh,
    // },
    {
        id:33,
        Name:"Nidhi Gangta",
        alt:"Nidhi Gangta",
        Image:NidhiGangta,
    },
    // {
    //     id:34,
    //     Name:"Adarsh Cheema",
    //     alt:"Adarsh Cheema",
    //     Image:AdarshCheema,
    // },
    // {
    //     id:35,
    //     Name:"Anupriya Parmar",
    //     alt:"Anupriya Parmar",
    //     Image:AnupriyaParmar,
    // },
    {
        id:36,
        Name:"Arnav Bohra",
        alt:"Arnav Bohra",
        Image:ArnavBohra,
    },
    // {
    //     id:37,
    //     Name:"Laxmi Kushwaha",
    //     alt:"Laxmi Kushwaha",
    //     Image:LaxmiKushwaha,
    // },
    {
        id:38,
        Name:"Rajveer Dey",
        alt:"Rajveer Dey",
        Image:RajveerDey,
    },
    // {
    //     id:39,
    //     Name:"Ranveer Singh (Guddu Rana)",
    //     alt:"Ranveer Singh",
    //     Image:RanveerSingh,
    // },
    // {
    //     id:40,
    //     Name:"Sarvesh Gupta",
    //     alt:"Sarvesh Gupta",
    //     Image:SarveshGupta,
    // },
    // {
    //     id:41,
    //     Name:"Tushar Chawla",
    //     alt:"Tushar Chawla",
    //     Image:TusharChawla,
    // },
];

export default AlumniData;