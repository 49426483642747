import React from 'react'
import "../CSS/Testimonial.css";
import TestimonialData from '../Component/TestimonialData';
const Testimonial = () => {

  const hrStyle = {
    margin:"30px 0px 30px 0px "
  }
  return (
    <>

      {/* /////////////////////////////////////////// section 1 //////////////////////////////////////////// */}

      <section className='Testimonial-Section01'>
        <div className='Testimonial-Main-Heading'>
          <h1>
            Testimonial
          </h1>
        </div>
      </section>

      {/* /////////////////////////////////////////// section 2 //////////////////////////////////////////// */}

      <section className='Testimonial-Section02'>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              {
                TestimonialData.map((item, index) => {
                  const { id, Name, Image, Discription } = item
                  return (
                    <>
                      <div className='Main-Testimonial-Container'>
                        <div className='row'>
                          <div className='col-md-3'>
                            <div className='Testimonial-Image-Con'>
                              <div className='Testimonial-Images'>
                                <img src={Image} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className='col-md-9'>
                            <div className='Testimonial-Info-Con'>
                              <div className='Testimonial-Name'>
                                <h4>{Name}</h4>
                              </div>
                              <div className='Testimonial-Description'>
                                <p>{Discription}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={hrStyle}></hr>

                    </>
                  )
                })
              }
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Testimonial