import React from 'react';
import "../Certificates/Certificates.css";

import certificates01 from "../../Images/Certificates/Certificates 01.png";
import certificates02 from "../../Images/Certificates/Certificates 02.png";
import certificates03 from "../../Images/Certificates/Certificates 03.png";
import certificates04 from "../../Images/Certificates/Certificates 04.JPG";
import certificates05 from "../../Images/Certificates/Certificates 05.png";
import certificates06 from "../../Images/Certificates/Certificates 06.png";
import certificates07 from "../../Images/Certificates/Certificates 07.png";
import certificates08 from "../../Images/Certificates/Certificates 08.png";
import certificates09 from "../../Images/Certificates/Certificates 09.png";
import certificates10 from "../../Images/Certificates/Certificates 10.png";
import certificates11 from "../../Images/Certificates/Certificates 12.JPG";
import certificates12 from "../../Images/Certificates/Certificates 11.png";
import certificates13 from "../../Images/Certificates/Certificates 13.JPG";
import certificates14 from "../../Images/Certificates/Certificates 14.JPG";
import certificates15 from "../../Images/Certificates/Certificates 15.JPG";
import certificates16 from "../../Images/Certificates/Certificates 16.JPG";
import certificates17 from "../../Images/Certificates/Certificates 17.JPG";
import certificates18 from "../../Images/Certificates/Certificates 18.JPG";
import certificates19 from "../../Images/Certificates/Certificates 19.JPG";
import certificates20 from "../../Images/Certificates/Certificates 20.JPG";
import certificates21 from "../../Images/Certificates/Certificates 21.JPG";
import certificates22 from "../../Images/Certificates/Certificates 22.JPG";
import certificates23 from "../../Images/Certificates/Certificates 23.JPG";
import certificates24 from "../../Images/Certificates/Certificates 24.JPG";

import Footer from "../../Component/FooterComponent";

const Certificates = () => {
    return (
        <>
            <section className='certificates-Section'>
                <div className='certificates-banner-Image'>
                    <div className='certificates-Banner-text'>
                    </div>
                </div>
            </section>

            <section className='certificates-section-2-con'>
                <div className='container'>
                    <div className='certificates-section-2-content-main-con'>
                        <div className='certificates-section-2content-con'>
                            <h2>Certificate Program in Acting & Theatre</h2>
                            <p>At ABSS Acting and Theatre Institute, we believe in recognizing and celebrating your hard work and dedication. Upon successful completion of our acting course, students are awarded a certificate that validates their training and skills. Additionally, after each of our theatre shows, participants receive a certificate for their performance in that specific production. These certificates serve as a testament to your talents and accomplishments, providing a valuable credential that will enhance your career in the performing arts. Join us at ABSS and take significant strides toward achieving your professional acting goals.</p>
                            <p>Our Certificate Program in Acting & Theatre offers comprehensive training designed to cultivate and enhance your acting skills. Upon the successful completion of the program, participants will have the opportunity to showcase their talents in a final performance. The best actors, as judged by our esteemed panel, will be awarded a certificate of excellence by the institute, recognizing their dedication and outstanding achievements in the field of acting and theatre. Join us to transform your passion for acting into a professional skill set.</p>
                        </div>
                            <div className='certificates-section-2content-con'>
                                <h2>Convocation Ceremony Invitation</h2>
                                <p>ABSS Acting and Theatre Institute is delighted to invite all students to our annual convocation ceremony. This special event celebrates the achievements of our graduates, who will be awarded their certificates by a prominent personality from the film and television industry. Join us for an evening of recognition, inspiration, and celebration as we honor the hard work and dedication of our talented students. Don't miss this opportunity to be part of a memorable occasion!</p>
                            </div>

                    </div>

                    <div className='certificates-section-2-Images-main-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='certificates-section-2-Images-main-con01'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates01} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates02} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='certificates-section-2-Images02'>
                                        <img src={certificates03} alt='' />
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates04} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates05} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='certificates-section-2-Images-main-con01'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates06} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates07} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='certificates-section-2-Images03'>
                                        <img src={certificates08} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='certificates-section-2-Images-main-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='certificates-section-2-Images-main-con01'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates09} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates10} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='certificates-section-2-Images03'>
                                        <img src={certificates11} alt='' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='certificates-section-2-Images-main-con01'>
                                    <div className='row'>
                                        <div className='certificates-section-2-Images02'>
                                            <img src={certificates14} alt='' />
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates12} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates13} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates15} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates16} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='certificates-section-2-Images-main-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='certificates-section-2-Images-main-con01'>
                                    <div className='row'>
                                        <div className='certificates-section-2-Images02'>
                                            <img src={certificates17} alt='' />
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates18} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates19} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates20} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates21} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='certificates-section-2-Images-main-con01'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates22} alt='' />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='certificates-section-2-Images01'>
                                                <img src={certificates23} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='certificates-section-2-Images03'>
                                        <img src={certificates24} alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </>
    )
}

export default Certificates