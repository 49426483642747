import React, { useEffect, useState } from 'react';
// import queryString from 'query-string'
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import "../CSS/EventInfo.css"
import EventsData from '../Component/EventsData';

import demo from "../Images/jhing-premachi.jpg";

import Image1 from "../Images/HomeClass02.png";
import Image2 from "../Images/HomeClass03.jpg";
import Image3 from "../Images/HomeClass04.jpg";

import FooterComponent from '../Component/FooterComponent';

const EventInfo = () => {

  const [eventId, setEventId] = useState()

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  console.log(id);


  // document.write(id)
  // const { id } = useParams("")
  // console.log(id)


  const [edata, setEdata] = useState(EventsData)
  // const [Fdata,setFdata] = useState([])


  const EventFdata = edata.find(e => e.id == id)
  // setFdata(EventFdata);
  console.log(EventFdata.Date)

  const EventImage = EventFdata.EImage





  return (
    <>

      {/* <h1>{EventFdata.id}</h1>


      <h1 style={{color:"black"}} >{EventFdata.Date}</h1> */}

      {/* //////////////////////////// Section 1 ///////////////////////////// */}


      <section className='EventInfoSection01'>
        <div className='EVSection-Image-con'>
          <div className='EVSection-Image'>
          </div>
          <div className='EVSection-Heading'>
            <h1>{EventFdata.EMDescription}</h1>
          </div>
        </div>
      </section>

      {/* //////////////////////////// Section 2 ///////////////////////////// */}

      <section className='EventInfoSection02'>
        <div className='EVSection2Image-con'>
          <img src={EventImage} alt="" />
        </div>

        <div className='EVSection2-Information'>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Quisquam saepe blanditiis earum molestiae reiciendis, consequuntur
            non eius! Omnis, ipsum deserunt! Porro asperiores cupiditate nobis
            quidem eaque sapiente qui unde rerum, non officiis quos aliquam ullam
            itaque repellendus quae natus dignissimos.
          </p>
        </div>

        <div className='EVSection2-Images-4'>
          <div className='row'>

            <div className='col-md-3'>
              <div className='EVSection2ImageCon'>
                <div className='EVSectionImage'>
                  <img src={Image1} alt="" />
                </div>
              </div>
            </div>


            <div className='col-md-3'>
              <div className='EVSection2ImageCon'>
                <div className='EVSectionImage'>
                  <img src={Image2} alt="" />
                </div>
              </div>
            </div>

            <div className='col-md-3'>
              <div className='EVSection2ImageCon'>
                <div className='EVSectionImage'>
                  <img src={Image3} alt="" />
                </div>
              </div>
            </div>

            <div className='col-md-3'>
              <div className='EVSection2ImageCon'>
                <div className='EVSectionImage'>
                  <img src={Image2} alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>



      <FooterComponent />


    </>
  )
}

export default EventInfo;