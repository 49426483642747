import React from 'react';
import "../Faculty/NishthaTiwari.css";
import Footer from "../../Component/FooterComponent";
import NishthaTiwariImage from "../../Images/ABSS Teachers/Nishtha Tiwari.png";

const NishthaTiwari = () => {
    return (
        <>
            <section className='NishthaTiwari-Section'>
                <div className='NishthaTiwari-banner-Image'>
                    <div className='NishthaTiwari-Banner-text'>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-2'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section2-con'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='rajendra-tiwari-Section2-Image-con'>
                                    <div className='rajendra-tiwari-Section2-Image'>
                                        <img src={NishthaTiwariImage} alt='' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='rajendra-tiwari-Section2-details-con'>
                                    <div className='rajendra-tiwari-Section2-details-head'>
                                        <h3 className='rajendra-tiwari-text-style'>Nishtha Tiwari</h3>
                                    </div>
                                    <p>Film production is Nishtha Tiwari's area of expertise. She specializes in camera and technical issues. Possessing a thorough understanding of the industry, she teaches prospective filmmakers using her knowledge and experience as a faculty member. Nishtha started her career in the film business with a strong foundation, having graduated in 2016 with a one-year Diploma in Filmmaking from the L.S. Raheja Institute in Worli, Mumbai. </p>
                                </div>
                            </div>
                        </div>
                        <div className='rajendra-tiwari-Section2-details-para'>
                            <p>Her varied skill set and commitment to her work have allowed her to work on multiple web series and serials, where she was a successful creative director who brought new ideas and creativity to every project. Nishtha is an excellent asset to both the educational institution where she teaches and the films she participates in, thanks to her enthusiasm for filmmaking and her practical expertise and technical proficiency.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='rajendra-tiwari-Section-3'>
                <div className='container'>
                    <div className='rajendra-tiwari-Section-3-con'>
                        <div className='rajendra-tiwari-Section2-details-head'>
                            <h3 className='rajendra-tiwari-text-style'>WORKING EXPERIENCE- </h3>
                        </div>
                        <div className='rajendra-tiwari-Section-3-points-con'>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- Currently employed at Shemaroo PVT LTD in Andheri East, Mumbai, as an executive producer</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- Recently served as CD for the television series Raazmal at Rashmi Sharma Telefilms.</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- Just finished filming the CD for the Hindi-Chhatisgarhi film DANDAKARANYA.</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- Served as the creative director for Balaji Telefilms' Nagin 6.</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- Spent five months as a creative employee at Status Air Vision Production.</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- Serve as an artistic creative for the web series Siksha Mandal, which stars Gauhar Khan.</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- Served as Creative Head for Rashmi Sharma Productions on the set of SASURAL SIMAR KA-2.</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- Five months, from January 2021 to May 2021, of experience working with Rashmi Sharma Productions as an ACH in SAATH NIBHANA SATHIYA-2.</h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- 2.5 years (2018–2020) of experience in the S3 Infomedia criminal alert production as an associate, stylist, costume director, and ACH. </h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- As creative director, a short film featuring Sneha Ullal and other cast members I have directed two music albums, directed short films, worked as an associate director on a web series, coordinated backstage work in theater for the past seven years, and performed as an actor in Crime Alert episodes. I have also worked as a costume designer in episodics. include the camera, props, music, lights, and scene.</h3>
                            </div>
                            {/* <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- I have done episodic as an actor in crime alert </h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- Worked as costume designer in episodic </h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- I have made short films as a director </h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- I have made 2 music albums as director </h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- I have worked in a web series as an associate director </h3>
                            </div>
                            <div className='rajendra-tiwari-Section-3-points'>
                                <h3>- I have managed back stage work in theatre from last 7 years. Including- music, lights, set, properties, costume and camera. </h3>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default NishthaTiwari