import Event01 from "../Images/Events/news1.JPG";
import Event02 from "../Images/Events/news2.JPG";
import Event03 from "../Images/Events/news3.JPG";
import Event04 from "../Images/Events/news4.jpg";
import Event05 from "../Images/Events/news5.JPG";
import Event06 from "../Images/Events/news6.JPG";
import Event07 from "../Images/Events/news7.JPG";
import Event08 from "../Images/Events/news8.JPG";
import Event09 from "../Images/Events/news9.JPG";
import Event10 from "../Images/Events/news10.JPG";
import Event11 from "../Images/Events/news11.jpg";
import Event12 from "../Images/Events/news12.jpg";
import Event13 from "../Images/Events/news13.jpg";
import Event14 from "../Images/Events/news14.jpg";
import Event15 from "../Images/Events/news15.jpg";
import Event16 from "../Images/Events/news16.jpg";
import Event17 from "../Images/Events/news17.jpg";
import Event18 from "../Images/Events/news18.jpg";
import Event19 from "../Images/Events/news19.jpg";
import Event20 from "../Images/Events/news20.jpg";
import Event21 from "../Images/Events/news21.jpg";
import Event22 from "../Images/Events/news22.jpg";
import Event23 from "../Images/Events/news23.jpg";
import Event24 from "../Images/Events/news24.jpg";
import Event25 from "../Images/Events/news25.jpg";
import Event26 from "../Images/Events/news26.jpg";
import Event27 from "../Images/Events/news27.jpg";
import Event28 from "../Images/Events/news28.jpg";
import Event29 from "../Images/Events/news29.jpg";
import Event30 from "../Images/Events/news30.jpg";
import Event31 from "../Images/Events/news31.jpg";
import Event32 from "../Images/Events/news32.jpg";
import Event33 from "../Images/Events/news33.jpg";
import Event34 from "../Images/Events/news34.jpg";
import Event35 from "../Images/Events/news35.jpg";
import Event36 from "../Images/Events/news36.jpg";
import Event37 from "../Images/Events/news37.jpg";
import Event38 from "../Images/Events/news38.jpg";
import Event39 from "../Images/Events/news39.jpg";
import Event40 from "../Images/Events/news40.jpg";
import Event41 from "../Images/Events/news41.jpg";
import Event42 from "../Images/Events/news42.jpg";
import Event43 from "../Images/Events/news43.jpg";
import Event44 from "../Images/Events/news44.jpg";
import Event45 from "../Images/Events/news45.jpg";
import Event46 from "../Images/Events/news46.jpg";
import Event47 from "../Images/Events/news47.jpg";
import Event48 from "../Images/Events/news48.jpg";
import Event49 from "../Images/Events/news49.jpg";
import Event50 from "../Images/Events/news50.jpg";
import Event51 from "../Images/Events/news51.jpg";
import Event52 from "../Images/Events/news52.jpg";
import Event53 from "../Images/Events/news53.jpg";
import Event54 from "../Images/Events/news54.jpg";
import Event55 from "../Images/Events/news55.jpg";
import Event56 from "../Images/Events/news56.jpg";
import Event57 from "../Images/Events/news57.jpg";
import Event58 from "../Images/Events/news58.jpg";
import Event59 from "../Images/Events/news59.jpg";
import Event60 from "../Images/Events/news60.jpg";
import Event61 from "../Images/Events/news61.jpg";
import Event62 from "../Images/Events/news62.jpg";
import Event63 from "../Images/Events/news63.jpg";
import Event64 from "../Images/Events/news64.jpg";
import Event65 from "../Images/Events/news65.jpg";
import Event66 from "../Images/Events/news66.jpg";
import Event67 from "../Images/Events/news67.jpg";
import Event68 from "../Images/Events/news68.jpg";
import Event69 from "../Images/Events/news69.jpg";




const EventsData = [
    {
        id:"1",
        EImage:Event01,
    },
    {
        id:"2",
        EImage:Event02,
    },
    {
        id:3,
        EImage:Event03,
    },
    {
        id:4,
        EImage:Event04,
    },
    {
        id:5,
        EImage:Event05,
    },
    {
        id:6,
        EImage:Event06,
    },
    {
        id:7,
        EImage:Event07,
    },
    {
        id:8,
        EImage:Event08,
    },
    {
        id:9,
        EImage:Event09,
    },
    {
        id:10,
        EImage:Event10,
    },
    {
        id:11,
        EImage:Event11,
    },
    {
        id:12,
        EImage:Event12,
    },
    {
        id:13,
        EImage:Event13,
    },
    {
        id:14,
        EImage:Event14,
    },
    {
        id:15,
        EImage:Event15,
    },
    {
        id:16,
        EImage:Event16,
    },
    {
        id:17,
        EImage:Event17,
    },
    {
        id:18,
        EImage:Event18,
    },
    {
        id:19,
        EImage:Event19,
    },
    {
        id:20,
        EImage:Event20,
    },
    {
        id:21,
        EImage:Event21,
    },
    {
        id:22,
        EImage:Event22,
    },
    {
        id:23,
        EImage:Event23,
    },
    {
        id:24,
        EImage:Event24,
    },
    {
        id:25,
        EImage:Event25,
    },
    {
        id:26,
        EImage:Event26,
    },
    {
        id:27,
        EImage:Event27,
    },
    {
        id:28,
        EImage:Event28,
    },
    {
        id:29,
        EImage:Event29,
    },
    {
        id:30,
        EImage:Event30,
    },
    {
        id:31,
        EImage:Event31,
    },
    {
        id:32,
        EImage:Event32,
    },
    {
        id:33,
        EImage:Event33,
    },
    {
        id:34,
        EImage:Event34,
    },
    {
        id:35,
        EImage:Event35,
    },
    {
        id:36,
        EImage:Event36,
    },
    {
        id:37,
        EImage:Event37,
    },
    {
        id:38,
        EImage:Event38,
    },
    {
        id:39,
        EImage:Event39,
    },
    {
        id:40,
        EImage:Event40,
    },
    {
        id:41,
        EImage:Event41,
    },
    {
        id:42,
        EImage:Event42,
    },
    {
        id:43,
        EImage:Event43,
    },
    {
        id:44,
        EImage:Event44,
    },
    {
        id:45,
        EImage:Event45,
    },
    {
        id:46,
        EImage:Event46,
    },
    {
        id:47,
        EImage:Event47,
    },
    {
        id:48,
        EImage:Event48,
    },
    {
        id:49,
        EImage:Event49,
    },
    {
        id:50,
        EImage:Event50,
    },
    {
        id:51,
        EImage:Event51,
    },
    {
        id:52,
        EImage:Event52,
    },
    {
        id:53,
        EImage:Event53,
    },
    {
        id:54,
        EImage:Event54,
    },
    {
        id:55,
        EImage:Event55,
    },
    {
        id:56,
        EImage:Event56,
    },
    {
        id:57,
        EImage:Event57,
    },
    {
        id:58,
        EImage:Event58,
    },
    {
        id:59,
        EImage:Event59,
    },
    {
        id:60,
        EImage:Event60,
    },
    {
        id:61,
        EImage:Event61,
    },
    {
        id:62,
        EImage:Event62,
    },
    {
        id:63,
        EImage:Event63,
    },
    {
        id:64,
        EImage:Event64,
    },
    {
        id:65,
        EImage:Event65,
    },
    {
        id:66,
        EImage:Event66,
    },
    {
        id:67,
        EImage:Event67,
    },
    {
        id:68,
        EImage:Event68,
    },
    {
        id:69,
        EImage:Event69,
    },
];

export default EventsData;