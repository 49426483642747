
import Gallery1 from "../Images/Gallery/Gallery1.avif";
import Gallery2 from "../Images/Gallery/Gallery2.avif";
import Gallery3 from "../Images/Gallery/Gallery3.avif";
import Gallery4 from "../Images/Gallery/Gallery4.avif";
import Gallery5 from "../Images/Gallery/Gallery5.avif";
import Gallery6 from "../Images/Gallery/Gallery6.avif";
import Gallery7 from "../Images/Gallery/Gallery7.avif";
import Gallery8 from "../Images/Gallery/Gallery8.avif";
import Gallery9 from "../Images/Gallery/Gallery9.avif";
import Gallery10 from "../Images/Gallery/Gallery10.avif";
import Gallery11 from "../Images/Gallery/Gallery11.avif";
import Gallery12 from "../Images/Gallery/Gallery12.avif";
import Gallery13 from "../Images/Gallery/Gallery13.avif";
import Gallery14 from "../Images/Gallery/Gallery14.avif";
import Gallery15 from "../Images/Gallery/Gallery15.avif";
import Gallery16 from "../Images/Gallery/Gallery16.avif";
import Gallery17 from "../Images/Gallery/Gallery17.avif";
import Gallery18 from "../Images/Gallery/Gallery18.avif";
import Gallery19 from "../Images/Gallery/Gallery19.avif";
import Gallery20 from "../Images/Gallery/Gallery20.avif";
import Gallery21 from "../Images/Gallery/Gallery21.avif";
import Gallery22 from "../Images/Gallery/Gallery22.avif";
import Gallery23 from "../Images/Gallery/Gallery23.avif";
import Gallery24 from "../Images/Gallery/Gallery24.avif";
import Gallery25 from "../Images/Gallery/Gallery25.avif";
import Gallery26 from "../Images/Gallery/Gallery26.avif";
import Gallery27 from "../Images/Gallery/Gallery27.avif";
import Gallery28 from "../Images/Gallery/Gallery28.avif";
import Gallery29 from "../Images/Gallery/Gallery29.avif";
import Gallery30 from "../Images/Gallery/Gallery30.avif";


const ImageGalleryData  = [
    {
        id:1,
        gImage:Gallery1,
    },
    {
        id:2,
        gImage:Gallery2,
    },
    {
        id:3,
        gImage:Gallery3,
    },
    {
        id:4,
        gImage:Gallery4,
    },
    {
        id:5,
        gImage:Gallery5,
    },
    {
        id:6,
        gImage:Gallery6,
    },
    {
        id:7,
        gImage:Gallery7,
    },
    {
        id:8,
        gImage:Gallery8,
    },
    {
        id:9,
        gImage:Gallery9,
    },
    {
        id:10,
        gImage:Gallery10,
    },
    {
        id:11,
        gImage:Gallery11,
    },
    {
        id:12,
        gImage:Gallery12,
    },
    {
        id:13,
        gImage:Gallery13,
    },
    {
        id:14,
        gImage:Gallery14,
    },
    {
        id:15,
        gImage:Gallery15,
    },
    {
        id:16,
        gImage:Gallery16,
    },
    {
        id:17,
        gImage:Gallery17,
    },
    {
        id:18,
        gImage:Gallery18,
    },
    {
        id:19,
        gImage:Gallery19,
    },
    {
        id:20,
        gImage:Gallery20,
    },
    {
        id:21,
        gImage:Gallery21,
    },
    {
        id:22,
        gImage:Gallery22,
    },
    {
        id:23,
        gImage:Gallery23,
    },
    {
        id:24,
        gImage:Gallery24,
    },
    {
        id:25,
        gImage:Gallery25,
    },
    {
        id:26,
        gImage:Gallery26,
    },
    {
        id:27,
        gImage:Gallery27,
    },
    {
        id:28,
        gImage:Gallery28,
    },
    {
        id:29,
        gImage:Gallery29,
    },
    {
        id:30,
        gImage:Gallery30,
    },
];

export default ImageGalleryData;    