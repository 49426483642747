import React from 'react'
import "../CSS/Course1Month.css"
import Footer from "../Component/FooterComponent"
import CCImage from "../Images/HomeClass04.jpg"

import ShowImage from "../Images/section-separator-bottom.png";

const CrashCourse = () => {

    // const toggleMenu = document.querySelectorAll(".panel-heading");

    // toggleMenu.forEach(function (items) {
    //     items.addEventListener("click", function () {
    //         this.classList.toggle("change_icon");
    //         let thisParent = items.closest(".panels");
    //         let thisContent = thisParent.children[1];
    //         thisContent.classList.toggle("collapse");
    //     });
    // });


    return (
        <>

            {/* ///////////////////////////////////////// Section 1 //////////////////////////////////////////////////// */}

            <section className='CC-Section01'>
                <div className='container'>
                    <div className='CC-Title'>
                        <h1>1 MONTH CRASH COURSE IN ACTING  & THEATRE</h1>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='CCTimeSchedule'>
                                <div className='CCTimeSchedule-title'>
                                    <h3>Timing & Schedule</h3>
                                </div>
                                <div className='CCTimeSchedule-info'>
                                    <h5>New Batch : Start from 15th October 2022</h5>
                                    <h5>Course : Diploma in Acting & Theatre</h5>
                                    <h5>Schedule : Monday To Sunday  </h5>
                                    <h5>Age Criteria : 15 years and above</h5>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='CCFeeDetails'>
                                <div className='CCFeeDetails-title'>
                                    <h3>Fee Details</h3>
                                </div>
                                <div className='CCFeeDetails-info'>
                                    <h5>Admission Fee  :-    Rs. 1500/-</h5>
                                    <h5>Fee                          :-    Rs. 30000/-</h5>
                                    <h5>Payment Mode :-    Cash, Cheque, UPI Transfer & Online </h5>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* ///////////////////////////////////////////////// Show Image /////////////////////////////////////////////// */}
            <section className='showSection'>
                <div className='SHowImage'>
                    <img src={ShowImage} alt="" />
                </div>
            </section>
            {/* ///////////////////////////////////////// Section 2 ///////////////////////////////////////////////////////////////// */}

            <section className='CCourse-Section2'>
                <div className='container'>
                    <div className='CCourse_Schedule-Title'>
                        <h5>Schedule</h5>
                        <h4>Schedule</h4>
                    </div>
                    <div className='ToursInfo-Section3-Itinerary'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='CCourse-Container'>
                                    <div className='CCourse-Images'>
                                        <img src={CCImage} alt="" />
                                    </div>

                                </div>
                            </div>

                            <div className='col-md-8                                                                                                                                                                                                                         '>
                                <div className='CCourse-Second-Container'>
                                    <div className='CCourse-Schedule-heading'>
                                        <h4>Everyday Schedule</h4>
                                    </div>
                                    <div className='CCourse-Schedule-Info'>
                                        <ul>
                                            <li>Meditation Concentration & Imagination Physical Exercise  (Eyes-Face-Body) Theatre Play WorkshopTheatre Play Rehearsal Dance Class</li>
                                            <li>Improvisation Speech & Observation</li>
                                            <li>Body Language according to Character,Face Movement According Camera,Body Movement According Camera</li>
                                            <li>Voice Modulation  Variations Dialogue DeliveryPronunciation  Diction (Hindi, Reginal Language & Urdu Pronunciation)</li>
                                            <li>9 Ruses, Face Expressions, Emotions, Reactions, Opposite Reactions & Performances of various moods</li>
                                            <li>Characterization, Technical Details for Camera, Camera Facing, Eyes & Face Control according to Camera, Auditions Practice, Monologues, Show reel. </li>
                                            <li>Energy Label Improvement, Breathing, Sound Practice from Head to Stomach</li>
                                            <li>*Live Theatre Performance on stage,Audition Practice Every Week,Portfolio Shoot,Auditions Approach,Street play work,Short Film, Ad Film, Feature Film &
                                                Web Series Shooting Work,Accommodation Facilities </li>

                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}


export default CrashCourse